import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import MapOrderDialog from "../../../components/dialoges/MapOrderDialog";
import GoodsEntryTable from "../../../components/Tables/GoodsEntryTable";
import { Print, Sync, Edit, Delete, Visibility, CheckCircle } from "@mui/icons-material";
import PrintLabelDialog from "components/dialoges/PrintLabelDialog";
import moment from "moment";
import ViewGoodsEntryDialog from "./ViewGoodsEntryDialog";
import ViewOrderDialog from "components/dialoges/ViewOrderDialog";
import { Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import GECheckItemDialog from "components/dialoges/GECheckItemDialog";


const useStyles = makeStyles(componentStyles);

const GoodsEntries = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [goodsEntryList, setGoodsEntryList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchReceiveDate, setSearchReceiveDate] = useState({
    startDate: null,
    endDate: null
  });

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('ReceiveDate');
  const [order, setOrderData] = useState('asc');
  const [offset, setOffset] = useState(10);
  const [openMaporder, setOpenMaporder] = useState(false);
  const [goodsEntryData, setGoodsEntryData] = useState(null);
  const [openPrintLabelDialog, setOpenPrintLabelDialog] = useState(false);
  const [printLabelData, setPrintLabelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchStatus, setSearchStatus] = useState('InProgress');
  const [searchCustomerStatus, setSearchCustomerStatus] = useState('Pending');
  const [filterModel, setFilterModel] = useState({
    products: []
  });

  const [isCustomerData, setIsCustomerData] = useState(false)

  const [viewOrderId, setViewOrderId] = useState(null);
  const [openVieworder, setOpenVieworder] = useState(false);

  const [isReload, setIsReload] = useState(true);
  const [goodsEntryViewId, setGoodsEntryViewId] = useState(null);

  const [loadingRows, setLoadingRows] = useState({});
  const [viewGECheckDialog, setViewGECheckDialog] = useState({ isVisible: false, data: null, extraData: null })

  const handleStepCheckItem = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.GoodsEntryId]: true
    }));
    try {
      let res = await dataService('get', `GoodsEntryCheckItemStatusList/${row?.GoodsEntryId}`, '');
      if (res.status === 200 && res?.data?.length > 0) {
        setViewGECheckDialog({
          isVisible: true, data: res?.data, extraData: row
        })
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.GoodsEntryId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.GoodsEntryId]: false
      }));
    }
  }
  const columns = [
    {
      id: 'GoodsEntryNumber', numeric: false, disablePadding: false, label: '#', dataType: 'component', Component: (row) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
            <Tooltip title={row?.IsAllItemChecked ? "Checklist done" : "Checklist pending"}>
              <CheckCircle fontSize="large" color={row?.IsAllItemChecked ? "success" : "error"} onClick={() => handleStepCheckItem(row)} />
            </Tooltip>
            <Typography sx={{ fontSize: isMobile ? "10px !important" : "12px !important" }}>{row?.GoodsEntryNumber}</Typography>
          </Box>
        )
      }
    },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'ReceiveDate', numeric: false, disablePadding: true, label: 'Receive Date', dataType: 'date' },
    { id: 'Rolls', numeric: false, disablePadding: false, label: 'Rolls', dataType: 'string' },
    { id: 'Bundles', numeric: false, disablePadding: false, label: 'Bundles', dataType: 'string' },
    { id: 'Samples', numeric: false, disablePadding: false, label: 'Samples', dataType: 'string' },
    { id: 'LRNumbers', numeric: false, disablePadding: false, label: 'LR Numbers', dataType: 'string' },
    { id: 'FileList', numeric: false, disablePadding: false, label: 'Files', dataType: 'jsonArray' },
    { id: 'DisplayRemarks', numeric: false, disablePadding: false, label: 'Remarks', dataType: 'string' },
  ];

  const customerColumns = [
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Name', dataType: 'string' },
    { id: 'LRNumbers', numeric: false, disablePadding: false, label: 'LR Numbers', dataType: 'string' },
    { id: 'Bundles', numeric: false, disablePadding: false, label: 'Bundles', dataType: 'string' },
    { id: 'Transport', numeric: false, disablePadding: false, label: 'Transport', dataType: 'string' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!isCustomerData) {
        setIsLoading(true);
        let startDate = searchReceiveDate?.startDate === null ? null : moment(searchReceiveDate?.startDate).format('YYYY-MM-DD')
        let endDate = searchReceiveDate?.endDate === null ? null : moment(searchReceiveDate?.endDate).format('YYYY-MM-DD')

        let filProductIds = filterModel?.products?.length > 0 ? filterModel?.products.join(',') : null
        let res = await dataService('get', 'GoodsEntryList', `status=${searchStatus}&productIds=${filProductIds}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
        const startTime = performance.now();

        if (res?.status === 200 || res?.status === 304) {
          setGoodsEntryList(res?.data);
          setTotalRecord(res?.totalRecord);
        }
        setIsLoading(false);
        const duration = performance.now() - startTime;
        console.log(`SETTING UP DATA TO GoodsEntryList State ${duration}ms`);
      }
    }

    fetchData()
  }, [isCustomerData, page, searchText, order, orderBy, offset, isReload, searchReceiveDate, searchStatus, filterModel])



  useEffect(() => {
    const fetchDataCustomer = async () => {
      if (isCustomerData) {
        setIsLoading(true);
        let startDate = searchReceiveDate?.startDate === null ? null : moment(searchReceiveDate?.startDate).format('YYYY-MM-DD')
        let endDate = searchReceiveDate?.endDate === null ? null : moment(searchReceiveDate?.endDate).format('YYYY-MM-DD')

        let filProductIds = null
        let res = await dataService('get', 'CustomerGoodsEntryList', `status=${searchCustomerStatus}&productIds=${filProductIds}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);

        if (res?.status === 200 || res?.status === 304) {
          setGoodsEntryList(res?.data);
          setTotalRecord(res?.totalRecord);
        }
        setIsLoading(false);
      }
    }

    fetchDataCustomer()
  }, [isCustomerData, page, searchText, order, orderBy, isReload, offset, searchReceiveDate, searchCustomerStatus, filterModel])



  const handleEdit = (row) => {
    history.push(`update-goods-entry/${row.GoodsEntryId}`)

  };

  const handleSync = (row) => {
    history.push(`sync-goods-entry/${row.CustomerId}/${row.CustomerGoodsEntryID}`)
  }

  const viewAction = (row) => {
    //Status, CreatedAt, UpdatedBy, BillingAddress, BillingCity, BillingState, BillingCountryId, BillingCountry, BillingZipCode, BillingEmail, BillingContactPhoneNumber, ShippingAddress, ShippingCity, ShippingState, ShippingCountry, ShippingCountryId, ShippingZipCode, ShippingContactPerson, ShippingEmail, ShippingContactPhoneNumber

    setOpen(true);
    setGoodsEntryViewId(row?.GoodsEntryId);
    //history.push(`update-goodsEntry/${row.GoodsEntryId}`)

  };

  const handleMapOrderDialog = (row) => {
    setOpenMaporder(true);
    setGoodsEntryData(row);
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `GoodsEntryDelete/${row.GoodsEntryId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const handlePrintLabel = async (row) => {
    setOpenPrintLabelDialog(true);
    setPrintLabelData(row);
  };

  const handleViewOrder = async (orderId) => {
    setViewOrderId(orderId);
    setOpenVieworder(true)
  };


  const tableData = () => {

    return <GoodsEntryTable
      view="goods-entry"
      title="Goods Entries"
      columns={isCustomerData ? customerColumns : columns}
      rows={goodsEntryList}
      page={page - 1}
      total={totalRecord}
      pagedata={setPage}
      setSearchText={setSearchText}
      order={order}
      orderBy={orderBy}
      orderData={setOrderData}
      orderByData={setOrderByData}
      actions={isCustomerData ? customerActionList : actionList}
      setOffset={setOffset}
      offset={offset}
      loading={isLoading}
      setSearchReceiveDate={setSearchReceiveDate}
      searchReceiveDate={searchReceiveDate}
      setSearchStatus={setSearchStatus}
      searchStatus={searchStatus}
      handleViewOrder={handleViewOrder}
      setFilterModel={setFilterModel}
      filterModel={filterModel}
      isCustomerData={isCustomerData}
      setIsCustomerData={setIsCustomerData}
      searchCustomerStatus={searchCustomerStatus}
      setSearchCustomerStatus={setSearchCustomerStatus}
      loadingRows={loadingRows}
      />
  }

  let actionList = [
    { label: 'View', icon: Visibility, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: Edit, iconColor: 'blue-500', action: handleEdit },
    { label: 'Map Order', icon: Edit, iconColor: 'blue-500', action: handleMapOrderDialog },
    { label: 'Delete', icon: Delete, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
    { label: 'Print Label', icon: Print, iconColor: 'green-500', action: handlePrintLabel }
  ]

  let customerActionList = [
    { label: "Sync", icon: Sync, iconColor: 'blue-500', action: handleSync, disabled: (row) => row?.Status === "Approved" }
  ]


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {tableData()}

      </Container>
      {open && goodsEntryViewId &&
        <ViewGoodsEntryDialog open={open} onClose={setOpen} GoodsEntryId={goodsEntryViewId} />
      }


      {goodsEntryData && openMaporder &&
        <MapOrderDialog from={'GoodsEntry'} mapTo={'Orders'} open={openMaporder} onClose={setOpenMaporder} MappingData={goodsEntryData} setIsReload={setIsReload} isReload={isReload} />
      }

      {openPrintLabelDialog && printLabelData &&
        <PrintLabelDialog onClose={setOpenPrintLabelDialog} open={openPrintLabelDialog} labelDetail={printLabelData} />
      }


      {openVieworder && viewOrderId &&
        <ViewOrderDialog open={openVieworder} onClose={setOpenVieworder} OrderId={viewOrderId} setIsReload={setIsReload} isReload={isReload} />
      }

      {viewGECheckDialog.isVisible &&
        <GECheckItemDialog
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewGECheckDialog}
          onClose={() => {
            setViewGECheckDialog({ data: null, isVisible: false, extraData: null })
          }}
        />
      }
    </>
  );
};

export default GoodsEntries;