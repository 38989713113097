import { Box, Container, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import CommonTable from 'components/Tables/CommonTable';

function ViewInventoryDialog({ onClose, open }) {
    const { isVisible, data, materialList } = open || {};

    const handleClose = () => {
        onClose(false);
    };

    const calculatedData = materialList?.map((material) => {
        const requiredQuantity =
            material.FilePerQuantity * (data?.quantity ? parseInt(data.quantity, 10) : 0);
        const amount = requiredQuantity * material.PricePerUnit;
        return {
            ...material,
            requiredQuantity,
            amount
        };
    });

    const totalAmount = calculatedData?.reduce((sum, material) => sum + (material.amount || 0), 0) || 0;

    const columns = [
        { id: 'MaterialName', numeric: false, disablePadding: true, label: 'Material', dataType: 'string' },
        { id: 'FilePerQuantity', numeric: false, disablePadding: false, label: 'File Per Quantity', dataType: 'string' },
        { id: 'requiredQuantity', numeric: false, disablePadding: false, label: 'Required Quantity', dataType: 'string' },
        { id: 'amount', numeric: false, disablePadding: false, label: 'Amount', dataType: 'string' },
    ];

    return (
        <Dialog
            onClose={handleClose}
            fullWidth={true}
            maxWidth="md"
            open={isVisible}
        >
            <div className={` border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle className="pb-1 w-full">
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="">
                                <span className="text-xl font-bold">{"Inventory Details"}</span>
                        </div>
                        <div className="flex flex-wrap justify-end">
                            <div className="mr-10">
                                <Typography variant="body2">
                                    <strong>Product Name:</strong> {data?.productName || '-'}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>File Name:</strong> {data?.fileName || '-'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                <Close
                    className="w-6 h-auto mr-2 cursor-pointer absolute right-0 top-2"
                    onClick={handleClose}
                />
            </div>

            {/* Content Section */}
            <DialogContent className="px-0 py-0" dividers>
                <Container
                    maxWidth={false}
                    component={Box}
                    marginTop="-1rem"
                    marginBottom="-1rem"
                >
                    <CommonTable
                        columns={columns}
                        rows={calculatedData || []}
                        pagination={false}
                        isSearch={false}
                    />
                </Container>
            </DialogContent>

            {/* Footer Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2} borderTop="1px solid #ccc">
                <Typography variant="body2">
                    <strong>File Size:</strong> {data?.fileSize || '-'}
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" fontWeight="bold">
                        Total Amount:
                    </Typography>
                    <Typography variant="body2" color="green" fontWeight="bold">
                        ₹{totalAmount.toFixed(2)}
                    </Typography>
                </Box>
            </Box>
        </Dialog>
    );
}

export default ViewInventoryDialog;
