import React from "react";
import { Checkbox, DialogContent } from '@mui/material';

function ViewOrderFile(props) {
    const { orderData } = props;

    return (
        <DialogContent>
            <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Full</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFull}</dd>
                            <dt className="text-sm font-medium text-gray-500">Full Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFullMtr}</dd>
                            <dt className="text-sm font-medium text-gray-500">Half</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBHalf}</dd>
                            <dt className="text-sm font-medium text-gray-500">Half Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBHalfMtr}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Total</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBTotal}</dd>
                            <dt className="text-sm font-medium text-gray-500">Total Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBTotalMtr}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Cutting</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Patta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingPatta}</dd>
                            <dt className="text-sm font-medium text-gray-500">Tukda</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingTukda}</dd>
                            <dt className="text-sm font-medium text-gray-500">Table</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingTable}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" color="primary" />ZigZag</span>
                            <span className=""><Checkbox checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" color="primary" />Interlock</span>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Binding</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Fabric Color</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 py-3 md:border-r border-red-300">{orderData?.BindingsFabricColor}</dd>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                <span className="md:border-r border-red-300 pr-3"> <Checkbox checked={orderData?.Bindings15 === 1 ? true : false} name="Bindings15" color="primary" />1.5</span>
                                <span className="md:border-r border-red-300 pr-3"><Checkbox checked={orderData?.Bindings11 === 1 ? true : false} name="Bindings11" color="primary" />11</span>
                                <span className=""><Checkbox checked={orderData?.BindingsFull ? true : false} name="BindingsFull" color="primary" />Full</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Kaan</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsKaan}</dd>
                            <dt className="text-sm font-medium text-gray-500">Patti</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsPatti}</dd>
                            <dt className="text-sm font-medium text-gray-500">Dori</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsDori}</dd>
                            <dt className="text-sm font-medium text-gray-500">Corner</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.BindingsCorner}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Top</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsTop || "-"}</dd>
                            <dt className="text-sm font-medium text-gray-500">Bottom</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.BindingsBottom || "-"}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.BindingsDieCut === 1 ? true : false} name="TagZigZag" color="primary" />Die Cut</span>
                            <span className=""><Checkbox checked={orderData?.BindingsFoil === 1 ? true : false} name="TagInterlock" color="primary" />Foil</span>
                            <span className=""><Checkbox checked={orderData?.BindingsTagPrinted === 1 ? true : false} name="TagInterlock" color="primary" />Tag Printed</span>
                            <span className=""><Checkbox checked={orderData?.BindingsMDF === 1 ? true : false} name="TagInterlock" color="primary" />MDF</span>
                            <span className=""><Checkbox checked={orderData?.BindingsCappaBoard === 1 ? true : false} name="TagInterlock" color="primary" />Cappa Board</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.BindingsRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-green-300 border-green-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Tagging</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagLogo === 1 ? true : false} name="TagLogo" color="primary" />Logo</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagCollection === 1 ? true : false} name="TagCollection" color="primary" />Collection</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagDesign === 1 ? true : false} name="TagDesign" color="primary" />Design</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagShed === 1 ? true : false} name="TagShed" color="primary" />Shed</span>
                            <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagInstruction === 1 ? true : false} name="TagInstruction" color="primary" />Instruction</span>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                          
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSymbol === 1 ? true : false} name="TagSymbol" color="primary" />Symbol</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSrNo === 1 ? true : false} name="TagDesign" color="primary" />Sr No.</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagWidth === 1 ? true : false} name="Width" color="primary" />Width ({orderData?.TagWidthValue})</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingRubs === 1 ? true : false} name="Width" color="primary" />Rubs ({orderData?.TaggingRubsValue})</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingGSM === 1 ? true : false} name="Width" color="primary" />Weight ({orderData?.TaggingGSMValue})</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.TagRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Photo Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-indigo-300">{orderData?.PhotosSize}</dd>


                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                Page
                                <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage" color="primary" />1</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage" color="primary" />2</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage" color="primary" />3</span>
                                <span><Checkbox checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" color="primary" />Lamination</span>
                            <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosColdPage === 1 ? true : false} name="PhotosColdPage" color="primary" />ColdPage</span>
                            <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" color="primary" />Matt</span>
                            <span className=""><Checkbox checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" color="primary" />Glossy</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.PhotosRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Top: {orderData?.ScreenTop}</dt>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Bottom: {orderData?.ScreenBottom}</dt>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Patti: {orderData?.ScreenPatti}</dt>
                            <dt className="text-sm font-medium text-gray-500">Printed: {orderData?.ScreenPrinted} <Checkbox checked={orderData?.ScreenPrintedBit === 1 ? true : false} name="PhotosGlossy" color="primary" /></dt>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.ScreenRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fittings</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Step Gap: {orderData?.FittingStepGap}</dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="md:border-r border-pink-300 pr-3"> <Checkbox checked={orderData?.FittingNumber?.includes(1) ? true : false} name="FittingNumber" color="primary" />1</span>
                                <span className="md:border-r border-pink-300 pr-3"><Checkbox checked={orderData?.FittingNumber?.includes(2) ? true : false} name="FittingNumber" color="primary" />2</span>
                                <span className="md:border-r border-pink-300 pr-3"><Checkbox checked={orderData?.FittingNumber?.includes(3) ? true : false} name="FittingNumber" color="primary" />3</span>
                                <span><Checkbox checked={orderData?.FittingNumber?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.FittingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
        </DialogContent>
    );
};
export default ViewOrderFile;