import React, { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CustomButton } from "components/Common/CustomButton";

const useStyles = makeStyles(componentStyles);

function CreateMaterial() {

  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const { id } = useParams();
  const PageTitle = id ? 'Update Material' : 'Add Material';

  const [materialModel, setMaterialModel] = useState({
    Name: '',
    Type: '',
    Unit: '',
    MinQuantity: '',
    Description: '',
  });

  const [materialValidationModel, setMaterialValidationModel] = useState({
    Name: { message: '', touched: false },
    Type: { message: '', touched: false },
    Unit: { message: '', touched: false },
    MinQuantity: { message: '', touched: false },
    IsValidationError: false
  });

  const [showSubmitLoading, setShowSubmitLoading] = useState(false); 

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  useEffect(() => {
    const getMMaterialData = async () => {
      let res = await dataService('get', `InventoryMaterialGet/${id}`, '');
      if (res.status === 200) {
        const MaterialData = res?.data;
        if (MaterialData) {
          setMaterialModel({
            Description : MaterialData.Description || "",
            Name : MaterialData.Name,
            MinQuantity : MaterialData.MinQuantity,
            Type : MaterialData.Type,
            Unit : MaterialData.Unit
          });
        }

      }
    }

    if (id) {
      getMMaterialData();
    }
  }, [id])


  const handleChange = (id, value) => {
    
    switch (id) {
      case 'Name':
        setMaterialModel({ ...materialModel, Name: value });
        setMaterialValidationModel({ ...materialValidationModel, Name: { message: checkEmptyValidation(value, 'Name'), touched: true } });
        break;
      case 'MinQuantity':
        setMaterialModel({ ...materialModel, MinQuantity: value });
        setMaterialValidationModel({ ...materialValidationModel, MinQuantity: { message: checkEmptyValidation(value, 'Minimum Quantity'), touched: true } });
        break;
      case 'Description':
        setMaterialModel({ ...materialModel, Description: value });
        break;
      case 'Unit':
        setMaterialModel({ ...materialModel, Unit: value });
        setMaterialValidationModel({ ...materialValidationModel, Unit: { message: checkEmptyValidation(value, 'Unit'), touched: true } });
        break;
      case 'Type':
        setMaterialModel({ ...materialModel, Type: value });
        setMaterialValidationModel({ ...materialValidationModel, Type: { message: checkEmptyValidation(value, 'Type'), touched: true } });
        break;
      default:
        break;
    }
  };

  const handleSubmitUser = async () => {


    if (!checkValidations()) {
      setShowSubmitLoading(true);
      let reqData = { ...materialModel };
      let res = await dataService('post', `InventoryMaterialSave/${id ? id : 0}`, reqData);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      setShowSubmitLoading(false);
      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialoge({ show: true, type: 'success', message: id ? 'Material updated successfully!' : 'Material created successfully!' });
        if (!id) {
          setTimeout(() => {
            history.push('inventory-materials')
          }, 500);
        }

      } else {
        setMessageDialoge({ show: true, type: 'error', message: res.message });
      }
    }
  }


  const checkValidations = () => {
    let validateErrors = {
      Name: { message: checkEmptyValidation(materialModel.Name, 'Name'), touched: true },
      MinQuantity: { message: checkEmptyValidation(materialModel.MinQuantity, 'Minimum Quantity'), touched: true },
      Type: { message: checkEmptyValidation(materialModel.Type, 'Type'), touched: true },
      Unit: { message: checkEmptyValidation(materialModel.Unit, 'Unit'), touched: true },
    }
    if (validateErrors.Name.message !== '' ||
      validateErrors.MinQuantity.message !== '' ||
      validateErrors.Type.message !== '' ||
      validateErrors.Unit.message !== ''
    ) {
      validateErrors.IsValidationError = true;
    }
    else {
      validateErrors.IsValidationError = false;
    }
    setMaterialValidationModel(validateErrors);
    return validateErrors.IsValidationError;
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container >
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Material Information
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={materialModel.Name}
                          onChange={e => handleChange('Name', e.target.value)}
                          error={materialValidationModel.Name.message !== '' && materialValidationModel.Name.touched}
                          helperText={materialValidationModel.Name.message !== '' && materialValidationModel.Name.touched ? materialValidationModel.Name.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Material Type</FormLabel>
                        <Select value={materialModel.Type} onChange={e => handleChange('Type', e.target.value)}>
                          <MenuItem value={'Raw Material'}>Raw Material</MenuItem>
                          <MenuItem value={'Finished Product'}>Finish Product</MenuItem>
                        </Select>
                      </FormGroup>
                      <FormHelperText sx={{color : "red", mt : -2}}>{materialValidationModel.Type.message !== '' && materialValidationModel.Type.touched ? materialValidationModel.Type.message : ''}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Unit</FormLabel>
                        <Select value={materialModel.Unit} onChange={e => handleChange('Unit', e.target.value)}>
                          <MenuItem value={'Meters'}>Meters</MenuItem>
                          <MenuItem value={'Kilograms'}>Kilograms</MenuItem>
                          <MenuItem value={'Pieces'}>Pieces</MenuItem>
                        </Select>
                        <FormHelperText sx={{color : "red", mt : 0.5}}>{materialValidationModel.Unit.message !== '' && materialValidationModel.Unit.touched ? materialValidationModel.Unit.message : ''}</FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Min Quantity</FormLabel>
                        <TextField
                          variant="filled"
                          name="MinQuantity"
                          component={Box}
                          type="number"
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={materialModel.MinQuantity}
                          onChange={e => handleChange("MinQuantity", e.target.value)}
                          error={materialValidationModel.MinQuantity.message !== '' && materialValidationModel.MinQuantity.touched}
                          helperText={materialValidationModel.MinQuantity.message !== '' && materialValidationModel.MinQuantity.touched ? materialValidationModel.MinQuantity.message : ''}
                          sx={{
                            '& input[type=number]::-webkit-outer-spin-button': {
                              WebkitAppearance: 'none'
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              WebkitAppearance: 'none'
                            }
                          }}
                          inputProps={{
                            min: 0
                          }}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          value={materialModel.Description}
                          autoComplete="off"
                          minRows={2}
                          multiline
                          onChange={e => handleChange('Description', e.target.value)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitUser} />
                      </Box>
                    </Grid>
                  </Grid>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

    </>
  );
}

export default CreateMaterial;
