import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Group as GroupIcon,
  Settings as SettingsIcon,
  Business,
  AccountBalance,
  Tune,
  CardTravel,
  Person,
  Work,
  FileCopyOutlined,
  CheckCircle,
  FactCheck,
  PanTool,
  MenuBook,
} from "@mui/icons-material";
import Header from "components/Headers/Header";

const SystemSettings = () => {
  const theme = useTheme();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState('administration');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleListItemClick = (route) => {
    history.push(route);
  };

  const ListItemSX = {
    mb: 1.5,
    borderRadius: 1.5,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.3s ease-in-out',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    py: 1,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.primary.main,
    },
    '&:last-child': {
      mb: 0,
    },
  };

  const AccordionSX = {
    mb: 3,
    borderRadius: '12px !important',
    '&:before': {
      display: 'none',
    },
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    '&.Mui-expanded': {
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      margin: '0 0 24px 0',
    },
  };

  const sections = [
    {
      id: 'master_settings',
      title: "Master Settings",
      description: "Manage core system master data and configurations",
      icon: <SettingsIcon />,
      items: [
        {
          title: "Users Management",
          description: "Manage system users and their access rights",
          icon: <GroupIcon />,
          route: "/admin/users",
        },
        {
          title: "Work Centers",
          description: "Configure and manage work center settings",
          icon: <AccountBalance />,
          route: "/admin/workcenters",
        },
        {
          title: "Machines",
          description: "Manage machine configurations and settings",
          icon: <Tune />,
          route: "/admin/machines",
        },
        {
          title: "Products",
          description: "Manage product catalog and specifications",
          icon: <CardTravel />,
          route: "/admin/products",
        },
        {
          title: "Customers",
          description: "Manage customer profiles and settings",
          icon: <Person />,
          route: "/admin/customers",
        },
      ],
    },
    {
      id: 'production_settings',
      title: "Production Settings",
      description: "Configure production related settings and parameters",
      icon: <Business />,
      items: [
        {
          title: "File Size Settings",
          description: "Configure product file size specifications",
          icon: <Work />,
          route: "/admin/product-files",
        },
        {
          title: "Files Management",
          description: "Manage system files and documents",
          icon: <FileCopyOutlined />,
          route: "/admin/files",
        },
        {
          title: "Hold Reasons",
          description: "Configure production hold reasons",
          icon: <PanTool />,
          route: "/admin/hold-reasons",
        },
        {
          title: "Catalogue Settings",
          description: "Manage product catalogues and displays",
          icon: <MenuBook />,
          route: "/admin/catalogues",
        },
      ],
    },
    {
      id: 'check_list',
      title: "Check List",
      description: "Manage check list settings",
      icon: <FactCheck />,
      items: [
        {
          title: "Flow Check List",
          description: "Configure production flow check parameters",
          icon: <CheckCircle />,
          route: "/admin/flow-check-list",
        },
        {
          title: "GE Check List",
          description: "Manage goods entry inspection checklist",
          icon: <FactCheck />,
          route: "/admin/goods-entry-check-list",
        },
      ],
    },
  ];

  return (
    <>
      <Header isSystemSettings={true}/>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={12} md={7}>
          {sections.map((section) => (
            <Accordion
              key={section.id}
              expanded={expanded === section.id}
              onChange={handleAccordionChange(section.id)}
              sx={AccordionSX}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem', width: '1.5rem', height: '1.5rem' }} />}
                sx={{
                  minHeight: 50,
                  backgroundColor: expanded === section.id ?
                    `${theme.palette.primary.main}15` : theme.palette.grey[50],
                  borderRadius: '12px 12px 0 0',
                  '&.Mui-expanded': {
                    minHeight: 50,
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '16px 0',
                  },
                }}
              >
                <Stack direction="row" spacing={2.5} alignItems="center" sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: expanded === section.id ?
                        theme.palette.primary.main : theme.palette.grey[300],
                      borderRadius: '8px',
                      p: 1,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {React.cloneElement(section.icon, { color: expanded === section.id ? 'white' : 'inherit', sx: { fontSize: '1.5rem' } })}
                  </Box>
                  <Stack spacing={0.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: expanded === section.id ?
                          theme.palette.primary.main : theme.palette.text.primary,
                        letterSpacing: '0.015em',
                      }}
                    >
                      {section.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '0.875rem',
                      }}
                    >
                      {section.description}
                    </Typography>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 3 }}>
                <List disablePadding>
                  {section.items.map((item, idx) => (
                    <ListItemButton
                      key={idx}
                      onClick={() => handleListItemClick(item.route)}
                      sx={ListItemSX}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 45,
                          color: theme.palette.primary.main,
                        }}
                      >
                        {React.cloneElement(item.icon, { sx: { fontSize: '1.3rem', width: '1.3rem', height: '1.3rem' } })}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              fontWeight: 500,
                              fontSize: '0.95rem',
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default SystemSettings;
