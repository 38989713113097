import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme, Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { showVerificationDialog } from "components/dialoges/VerifiyOTPDialog";
import { PRE_ORDER_STATUS_DATA } from "utils/constant";

import { currentUser } from "services/user-service";

const useStyles = makeStyles(componentStyles);

function PreOrder() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? 'Update Order Form Detail' : 'Add Order Form Detail';

    // Usef defined states
    const [preOrderModel, setPreOrderModel] = useState({
        PreOrderId: 0,
        CustomerName: "",
        CollectionName: "",
        Logo: "",
        Size: "",
        Buckram: "",
        SKU: "",
        MTR: "",
        Story: "",
        Interlock: false,
        ZigZag: false,
        Foil: false,
        Screen: false,
        Print: false,
        ECatalogue: false,
        Quantity: "",
        TechnicalDetails: "",
        Width: "",
        Weight: "",
        Rubs: "",
        Composition: "",
        SpecialFeatures: "",
        Status: null
    });

    // Usef defined states
    const [preOrderValidationModel, setPreOrderValidationModel] = useState({
        CustomerName: { message: '', touched: false },
        CollectionName: { message: '', touched: false },
        Logo: { message: '', touched: false },
        Size: { message: '', touched: false },
        Buckram: { message: '', touched: false },
        SKU: { message: '', touched: false },
        MTR: { message: '', touched: false },
        Quantity: { message: '', touched: false },    
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [isReload, setIsReload] = useState(false);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                await getPreOrderFormData();
            }
        }

        fetchData()
    }, [id, isReload])

    const getPreOrderFormData = async () => {
        let res = await dataService('get', `PreOrderGet/${id}`, '');
        if (res.status === 200) {
            setPreOrderModel({
                ...preOrderModel,
                PreOrderId: res?.data?.PreOrderId,
                CustomerName: res?.data?.CustomerName,
                CollectionName: res?.data?.CollectionName,
                Logo: res?.data?.Logo,
                Size: res?.data?.Size,
                Buckram: res?.data?.Buckram,
                SKU: res?.data?.SKU,
                MTR: res?.data?.MTR,
                Story: res?.data?.Story,
                Interlock: Boolean(res?.data?.Interlock),
                ZigZag: Boolean(res?.data?.ZigZag),
                Foil: Boolean(res?.data?.Foil),
                Screen: Boolean(res?.data?.Screen),
                Print: Boolean(res?.data?.Print),
                ECatalogue: Boolean(res?.data?.ECatalogue),
                Quantity: res?.data?.Quantity,
                TechnicalDetails: res?.data?.TechnicalDetails,
                Width: res?.data?.Width,
                Weight: res?.data?.Weight,
                Rubs: res?.data?.Rubs,
                Composition: res?.data?.Composition,
                SpecialFeatures: res?.data?.SpecialFeatures,
                Status: res?.data?.Status || null
            })
        }
    }

    const handleChange = (evt) => {
        setPreOrderModel({ ...preOrderModel, [evt.target.name]: evt.target.value });
        setPreOrderValidationModel({ ...preOrderValidationModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
    };

    const handleSubmitPreOrderForm = async () => {
        if (!checkValidations()) {

            setShowSubmitLoading(true);
            let reqData = { ...preOrderModel };
            if (id) {
                //TEMP HIDE VERIFICATION DIALOG

                showVerificationDialog('Verify Authorization', true, () => {
                    setShowSubmitLoading(false);
                }, async () => {
                    let res = await dataService('post', `PreOrderSave/${id ? id : 0}`, reqData);
                    handleSubmitResponse(res);
                })
            }
            else {
                if (currentUser()?.UserId) {
                    let res = await dataService('post', `PreOrderSave/${id ? id : 0}`, reqData);
                    handleSubmitResponse(res);
                } else {
                    let res = await dataService('post', `PreOrderSavePublic/${id ? id : 0}`, reqData);
                    handleSubmitResponse(res);
                }
                setShowSubmitLoading(false);
            }

        }

    }

    const handleSubmitResponse = async (res) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setShowSubmitLoading(false);
        if (res && (res.status === 200 || res.status === 304)) {
            setMessageDialoge({ show: true, type: 'success', message: id ? 'Pre order updated successfully!' : 'Pre order added successfully!' });
            if (!id) {
                if (currentUser()?.UserId) {
                    setTimeout(() => {
                        history.push('pre-orders')
                    }, 500);

                } else {
                    setPreOrderModel({
                        PreOrderId: 0,
                        CustomerName: "",
                        CollectionName: "",
                        Logo: "",
                        Size: "",
                        Buckram: "",
                        SKU: "",
                        MTR: "",
                        Story: "",
                        Interlock: false,
                        ZigZag: false,
                        Foil: false,
                        Screen: false,
                        Print: false,
                        ECatalogue: false,
                        Quantity: "",
                        TechnicalDetails: "",
                        Width: "",
                        Weight: "",
                        Rubs: "",
                        Composition: "",
                        SpecialFeatures: "",
                        Status: null
                    });
                    setPreOrderValidationModel({
                        CustomerName: { message: '', touched: false },
                        CollectionName: { message: '', touched: false },
                        Logo: { message: '', touched: false },
                        Size: { message: '', touched: false },
                        Buckram: { message: '', touched: false },
                        SKU: { message: '', touched: false },
                        MTR: { message: '', touched: false },
                        Quantity: { message: '', touched: false },
                        IsValidationError: false
                    });
                    setTimeout(() => {
                        setMessageDialoge({ show: false, type: '', message: "" });
                    }, 3000);
                }

            }
            else {
                setIsReload(!isReload);
            }

        } else {
            setMessageDialoge({ show: true, type: 'error', message: res.message });
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            CustomerName: { message: checkEmptyValidation(preOrderModel.CustomerName, 'Customer'), touched: true },
            CollectionName: { message: checkEmptyValidation(preOrderModel.CollectionName, 'Collection name'), touched: true },
            Logo: { message: checkEmptyValidation(preOrderModel.Logo, 'Logo'), touched: true },
            Size: { message: checkEmptyValidation(preOrderModel.Size, 'Size'), touched: true },
            Buckram: { message: checkEmptyValidation(preOrderModel.Buckram, 'Buckram'), touched: true },
            SKU: { message: checkEmptyValidation(preOrderModel.SKU, 'SKU'), touched: true },
            MTR: { message: checkEmptyValidation(preOrderModel.MTR, 'MTR'), touched: true },
            Quantity: { message: checkEmptyValidation(preOrderModel.Quantity, 'Quantity'), touched: true }
        }
        if (validateErrors.CustomerName.message !== '' || validateErrors.CollectionName.message !== '' || validateErrors.Logo.message !== '' ||
            validateErrors.SKU.message !== '' || validateErrors.Size.message !== '' || validateErrors.Buckram.message !== '' ||
            validateErrors.MTR.message !== '' || validateErrors.Quantity.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setPreOrderValidationModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    const handleCheckBoxes = (evt) => {
        setPreOrderModel({ ...preOrderModel, [evt.target.name]: evt.target.checked });
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <Box
                                    component={Typography}
                                    variant="h6"
                                    color={theme.palette.gray[600] + "!important"}
                                    paddingTop=".25rem"
                                    paddingBottom=".25rem"
                                    fontSize=".75rem!important"
                                    letterSpacing=".04em"
                                    marginBottom="1.5rem!important"
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Pre Order Information
                                </Box>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormLabel>Customer</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="CustomerName"
                                                    value={preOrderModel?.CustomerName}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.CustomerName.message !== '' && preOrderValidationModel.CustomerName.touched}
                                                    helperText={(preOrderValidationModel.CustomerName.message !== '' && preOrderValidationModel.CustomerName.touched) ? preOrderValidationModel.CustomerName.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Collection</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="CollectionName"
                                                    value={preOrderModel?.CollectionName}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.CollectionName.message !== '' && preOrderValidationModel.CollectionName.touched}
                                                    helperText={(preOrderValidationModel.CollectionName.message !== '' && preOrderValidationModel.CollectionName.touched) ? preOrderValidationModel.CollectionName.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Logo</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="Logo"
                                                    value={preOrderModel?.Logo}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.Logo.message !== '' && preOrderValidationModel.Logo.touched}
                                                    helperText={preOrderValidationModel.Logo.message !== '' && preOrderValidationModel.Logo.touched ? preOrderValidationModel.Logo.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Size</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="Size"
                                                    value={preOrderModel?.Size}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.Size.message !== '' && preOrderValidationModel.Size.touched}
                                                    helperText={preOrderValidationModel.Size.message !== '' && preOrderValidationModel.Size.touched ? preOrderValidationModel.Size.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Buckram</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="Buckram"
                                                    value={preOrderModel?.Buckram}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.Buckram.message !== '' && preOrderValidationModel.Buckram.touched}
                                                    helperText={preOrderValidationModel.Buckram.message !== '' && preOrderValidationModel.Buckram.touched ? preOrderValidationModel.Buckram.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>SKU</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="SKU"
                                                    value={preOrderModel?.SKU}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.SKU.message !== '' && preOrderValidationModel.SKU.touched}
                                                    helperText={preOrderValidationModel.SKU.message !== '' && preOrderValidationModel.SKU.touched ? preOrderValidationModel.SKU.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>MTR</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="MTR"
                                                    value={preOrderModel?.MTR}
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.MTR.message !== '' && preOrderValidationModel.MTR.touched}
                                                    helperText={preOrderValidationModel.MTR.message !== '' && preOrderValidationModel.MTR.touched ? preOrderValidationModel.MTR.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>Quantity</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Quantity"
                                                    width="100%"
                                                    type="number"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.Quantity}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                    error={preOrderValidationModel.Quantity.message !== '' && preOrderValidationModel.Quantity.touched}
                                                    helperText={preOrderValidationModel.Quantity.message !== '' && preOrderValidationModel.Quantity.touched ? preOrderValidationModel.Quantity.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                        {id &&
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <FormGroup>
                                                    <FormLabel>Status</FormLabel>
                                                    <>
                                                        <Autocomplete
                                                            id="pre-order-page-Status"
                                                            value={PRE_ORDER_STATUS_DATA.find(option => option.label === preOrderModel?.Status) || null}
                                                            options={PRE_ORDER_STATUS_DATA}
                                                            onChange={(event, newValue) => {
                                                                setPreOrderModel({ ...preOrderModel, Status: newValue?.label || '' });
                                                            }}
                                                            autoHighlight
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    placeholder="Select Status"
                                                                    autoComplete="new-password"
                                                                />
                                                            )}
                                                        />

                                                    </>
                                                </FormGroup>
                                            </Grid>}
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormLabel>Story</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Story"
                                                    multiline
                                                    minRows={4}
                                                    maxRows={6}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.Story}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={preOrderModel.Interlock}
                                                                onChange={handleCheckBoxes}
                                                                name="Interlock"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Interlock"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={preOrderModel?.ZigZag}
                                                                onChange={handleCheckBoxes}
                                                                name="ZigZag"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="ZigZag"
                                                    />
                                                </FormGroup>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={preOrderModel?.Foil}
                                                                onChange={handleCheckBoxes}
                                                                name="Foil"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Foil"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={preOrderModel?.Screen}
                                                                onChange={handleCheckBoxes}
                                                                name="Screen"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Screen"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={preOrderModel?.Print}
                                                                onChange={handleCheckBoxes}
                                                                name="Print"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Print"
                                                    />
                                                </FormGroup>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={preOrderModel?.ECatalogue}
                                                            onChange={handleCheckBoxes}
                                                            name="ECatalogue"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="E-Catalogue"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormLabel>Technical Details For a Tag & Photos</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="TechnicalDetails"
                                                    multiline
                                                    minRows={3}
                                                    maxRows={5}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.TechnicalDetails}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>Width</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="Width"
                                                    value={preOrderModel?.Width}
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>Weight</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    name="Weight"
                                                    value={preOrderModel?.Weight}
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} sm={id ? 6 : 4} lg={id ? 3 : 4}>
                                            <FormGroup>
                                                <FormLabel>Rubs</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Rubs"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.Rubs}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormLabel>Composition</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Composition"
                                                    multiline
                                                    minRows={3}
                                                    maxRows={5}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.Composition}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormLabel>Special Features</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="SpecialFeatures"
                                                    multiline
                                                    minRows={3}
                                                    maxRows={5}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    value={preOrderModel.SpecialFeatures}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>


                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={handleSubmitPreOrderForm}
                                                    disabled={showSubmitLoading}
                                                    className="bg-[#5e72e4] border-[#5e72e4]"
                                                >
                                                    Submit
                                                    {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

        </>
    );
}

export default PreOrder;
