import { DialogContent, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Close, CheckCircle } from '@mui/icons-material';
import ViewTable from 'components/Tables/ViewTable';
import React, { useEffect, useState } from "react";
import { dataService } from "services/data-service";
import ViewGoodsEntryDialog from 'views/admin/goodsEntry/ViewGoodsEntryDialog';

import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import GECheckItemDialog from "components/dialoges/GECheckItemDialog";

function ViewOrdersGEDialog(props) {
    const { onClose, open, OrderDetail, viewDialogData } = props;

    const [flowStepDataList, setFlowStepDataList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderByData] = useState('CreatedAt');
    const [order, setOrderData] = useState('desc');
    const [offset, setOffset] = useState(10);
    const [openGEDialoh, setOpenGEDialoh] = useState(false);
    const [goodsEntryViewId, setGoodsEntryViewId] = useState(false);
    const [isReload, setIsReload] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const GENumberEventHandle = (row) => {
        console.log('row: ', row)

        setGoodsEntryViewId(row?.GoodsEntryId)
        setOpenGEDialoh(true);
    }

    // const [loadingRows, setLoadingRows] = useState({});
    const [viewGECheckDialog, setViewGECheckDialog] = useState({ isVisible: false, data: null, extraData: null })

    const handleStepCheckItem = async (row) => {
        try {
            let res = await dataService('get', `GoodsEntryCheckItemStatusList/${row?.GoodsEntryId}`, '');
            if (res.status === 200 && res?.data?.length > 0) {
                setViewGECheckDialog({
                    isVisible: true, data: res?.data, extraData: row
                })
            }
        } catch (error) {}
    }

    const columns = [
        {
            id: 'IsAllItemChecked', numeric: false, disablePadding: false, label: 'Check', dataType: 'component', Component: (row) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <Tooltip title={row?.IsAllItemChecked ? "Checklist done" : "Checklist pending"}>
                            <CheckCircle fontSize="large" color={row?.IsAllItemChecked ? "success" : "error"} onClick={() => handleStepCheckItem(row)} />
                        </Tooltip>
                    </Box>
                )
            }
        },
        { id: 'GoodsEntryNumber', numeric: false, disablePadding: true, label: '#', dataType: 'string', action: GENumberEventHandle },
        { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
        { id: 'CustomerClient', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
        { id: 'ReceiveDate', numeric: false, disablePadding: false, label: 'Receive Date', dataType: 'date' },
        { id: 'Bundles', numeric: false, disablePadding: false, label: 'Bundles', dataType: 'string' },
        { id: 'Rolls', numeric: false, disablePadding: false, label: 'Rolls', dataType: 'string' },
        { id: 'Samples', numeric: false, disablePadding: false, label: 'Samples', dataType: 'string' },
        { id: 'LRNumbers', numeric: false, disablePadding: false, label: 'LRNumbers', dataType: 'string' },
        { id: 'Remarks', numeric: false, disablePadding: false, label: 'Remarks', dataType: 'string' }
    ];

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        fetchData()
    }, [page, order, orderBy, offset])

    const fetchData = async () => {
        let res = await dataService('get', `OrderGoodsEntryList/${OrderDetail?.OrderId}`);
        setFlowStepDataList(res?.data);
        if (res?.data) {
            setTotalRecord(res?.data?.length);
        }
    }

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
            <div className="flex flex-wrap items-center justify-between">
                {/* <DialogTitle id="form-dialog-title" >
                    <span className="text-2xl">{viewDialogData.title}</span>
                </DialogTitle> */}
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>

            <DialogContent>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="border-t border-gray-200">
                        <dl>

                            <ViewTable
                                title="Goods Detail"
                                columns={columns}
                                rows={flowStepDataList}
                                detail={OrderDetail}
                                page={page - 1}
                                total={totalRecord}
                                pagedata={setPage}
                                order={order}
                                orderBy={orderBy}
                                orderData={setOrderData}
                                orderByData={setOrderByData}
                                editAction={null}
                                setOffset={setOffset}
                                offset={offset}
                                isSearchable={false}
                            />
                        </dl>
                    </div>
                </div>
            </DialogContent>

            {openGEDialoh && goodsEntryViewId &&
                <ViewGoodsEntryDialog open={openGEDialoh} onClose={setOpenGEDialoh} GoodsEntryId={goodsEntryViewId} />
            }

            {viewGECheckDialog.isVisible &&
                <GECheckItemDialog
                    setIsReload={setIsReload}
                    isReload={isReload}
                    open={viewGECheckDialog}
                    onClose={() => {
                        setViewGECheckDialog({ data: null, isVisible: false, extraData: null })
                    }}
                    disabled={true}
                />
            }
        </Dialog>
    );
};
export default ViewOrdersGEDialog;