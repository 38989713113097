import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CustomButton } from "components/Common/CustomButton";


const useStyles = makeStyles(componentStyles);

function GECheckItem() {

    const classes = useStyles();

    const history = useHistory();

    const { id } = useParams();
    const PageTitle = id ? `Update GE Check Item` : `Add GE Check Item`;

    const [checkItemModel, setCheckItemModel] = useState({
        WorkCenterCheckItemId: null,
        CheckItem: null,
    });

    const [checkItemValidationSchema, setCheckItemValidationSchema] = useState({
        CheckItem: { message: '', touched: true },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async () => {
        if (id) {
            getCheckItemDetail();
        }
    }

    const getCheckItemDetail = async () => {
        let res = await dataService('get', `GoodsEntryCheckItemMasterGet/${id}`, '');
        if (res.status === 200) {
            let checkItemData = res?.data
            if (checkItemData) {
                setCheckItemModel({
                    ...checkItemModel,
                    CheckItem: checkItemData?.CheckItem,
                });
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'CheckItem':
                setCheckItemModel({ ...checkItemModel, CheckItem: value });
                setCheckItemValidationSchema({ ...checkItemValidationSchema, CheckItem: { message: checkEmptyValidation(value, `Check Item`), touched: true } });
                break;
        }
    };

    const handleSubmit = async () => {

        if (!checkValidations()) {
            const payload = {
                CheckItem: checkItemModel.CheckItem,
            }
            setShowSubmitLoading(true);
            let res = await dataService('post', `GoodsEntryCheckItemMasterSave/${id ? id : 0}`, payload);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? `GE check item updated successfully!` : 'GE check item created successfully!' });

                setTimeout(() => {
                    history.replace('/admin/goods-entry-check-list')
                }, 500);
            } else {
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }

    const checkValidations = () => {

        let validateErrors = {
            CheckItem: { message: checkEmptyValidation(checkItemModel.CheckItem, `Check Item`), touched: true }
        }
        if (
            validateErrors.CheckItem.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setCheckItemValidationSchema(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>

                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <div className="flex flex-wrap items-center mb-2">
                                                    <FormLabel className="mr-3 mb-0">Check Item</FormLabel>
                                                </div>
                                                <div className="flex flex-wrap items-center justify-start ">
                                                    <div className="w-full ">
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            placeholder="Enter check item"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={checkItemModel.CheckItem}
                                                            // className={classes1.errorTransform}
                                                            onChange={e => handleChange('CheckItem', e.target.value)}
                                                            error={checkItemValidationSchema.CheckItem.message !== '' && checkItemValidationSchema.CheckItem.touched}
                                                            helperText={checkItemValidationSchema.CheckItem.message !== '' && checkItemValidationSchema.CheckItem.touched ? checkItemValidationSchema.CheckItem.message : ''}
                                                            inputProps={{ maxLength: 50 }}
                                                        >
                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="text"
                                                            />
                                                        </TextField>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>


                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmit} />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default GECheckItem;