import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";

const useStyles = makeStyles(componentStyles);

const InventoryStockTransactionsList = () => {
  const classes = useStyles();
  const [transactionsList, setTransactionsList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    { id: 'MaterialName', numeric: false, disablePadding: true, label: 'Material', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    { id: 'TotalAmount', numeric: false, disablePadding: false, label: 'Total Amount', dataType: 'string' },
    { id: 'TransactionType', numeric: false, disablePadding: false, label: 'Transaction Type', dataType: 'string' },
    { id: 'TransactionDate', numeric: false, disablePadding: false, label: 'Transaction Date', dataType: 'date' },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let res = await dataService('get', 'InventoryStockTransactions', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setTransactionsList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  const handleDelete = async (row) => {
    try {
      let res = await dataService('post', `InventoryStockDelete/${row.StockId}`, null);
      if (res && (res.status === 200 || res.status === 304)) {
        setIsReload(!isReload);
      }
    } catch (e){
      console.log(e)
    }
   
  };

  let actionList = [
    // { label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
  ]

  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })

    actionList = actionList.filter(ac => {
      return ac.label !== 'Delete'
    })
  }

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Stock Transactions"
          columns={columns}
          rows={transactionsList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
    </>
  );
};

export default InventoryStockTransactionsList;