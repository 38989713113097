import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//import { selectedOrderFlow } from "../../generic/store";
import { useSelector } from 'react-redux';

import {Clear, Menu as MenuIcon, Forward as ForwardIcon, } from "@mui/icons-material"

// core components
import componentStyles from "assets/theme/components/sidebar.js";
import { currentUser } from "services/user-service";
//import { Accordion } from "@mui/material";
//import { Grid } from "@mui/material";
import { getProductListForStore } from "services/data-service";
import { getWorkCenterListForStore } from "services/data-service";


import Badge from '@mui/material/Badge';
import useSocket from "hooks/useSocket";

const useStyles = makeStyles(componentStyles);

export default function Sidebar({ routes, logo, dropdown, input }) {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderFlow, setOrderFlow] = React.useState([]);
  const [selectedOrderFlowIndx, setSelectedOrderFlowIndx] = React.useState(null);
  const selectedOrderFlow = useSelector(state => state.selectedOrderFlow); // show/hide cart sidebar
  // const selectedOrderFlowIndxStr = useSelector(state => state.selectedOrderFlowIndx); // show/hide cart sidebar
  const isMenuOpen = Boolean(anchorEl);


  const unreadTaskCount = useSocket('unreadTaskCount', 0);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };




  useEffect(() => {
    generateMenu();
  }, [selectedOrderFlow])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async() => {
    await getProductListForStore();
    await getWorkCenterListForStore();
    generateMenu()
  }

  const generateMenu = () => {

    let selected_flow = selectedOrderFlow;

    if (selected_flow?.selected_flow) {
      selected_flow = selected_flow?.selected_flow;
    }
    else {
      if (localStorage.getItem('selected_flow')) {
        selected_flow = JSON.parse(localStorage.getItem('selected_flow'))
      }
    }

    if (selected_flow) {
      let ordFlow = selected_flow?.workcenters;
      if(selected_flow?.product?.ProductId === 0){
        ordFlow = uniquWorkCenters(selected_flow?.workcenters);
        //ordFlow = [...new Set(selected_flow?.workcenters.map(({WorkCenterName})=>WorkCenterName))]
      }
      else{
        ordFlow = selected_flow?.workcenters?.filter((worCenter) => {return Number(worCenter.ProductId) === Number(selected_flow?.product?.ProductId)})
      }

      if (ordFlow) {
        setOrderFlow(ordFlow);
      }

    }
  };

  const uniquWorkCenters = (workcentersArr) => {
    var items = workcentersArr;
    var result = [];
    items.forEach(item => {
      let findW = result?.find(rs => {return rs?.WorkCenterName === item?.WorkCenterName})
      if(!findW){
        result.push(item);
      }
    });
    if(result?.length > 0){
      result.sort((a, b) => a.WorkCenterOrder - b.WorkCenterOrder)
    }
    return result;
  }
  

  const menuId = "responsive-menu-id";
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.filter(route => (route.showInSidebar && route?.roles?.includes(`${currentUser()?.Role}`)) ).map((prop, key) => {
        if (prop.divider) {
          return <Divider key={key} classes={{ root: classes.divider }} />;
        } else if (prop.title) {
          return (
            <Typography
              key={key}
              variant="h6"
              component="h6"
              classes={{ root: classes.title }}
              className="text-bold text-base bg-gray-50"
            >
              {prop.title}
            </Typography>
          );
        }
        
        let textContent = (
          <>

          <Box minWidth="2.25rem" display="flex" alignItems="center">
            {typeof prop.icon === "string" ? (
              <Box
                component="i"
                className={prop.icon + " " + classes["text" + prop.iconColor]}
              />
            ) : null}
            {typeof prop.icon === "object" || typeof prop.icon === "function" ? (
              <Box
                component={prop.icon}
                width="1.25rem!important"
                height="1.25rem!important"
                className={classes["text" + prop.iconColor]}
              />
            ) : null}
          </Box>

            {prop.name} {prop.name === 'My Task' && unreadTaskCount > 0 ? <Badge badgeContent={unreadTaskCount} color="error" className="ml-4 bg-red-900 text-gray-200 animate-pulse"></Badge> : null}
          </>
        );
        if (prop.href) {
          return (
            <ListItem
              key={key}
              component={"a"}
              href={prop.href}
              onClick={() => {
                handleMenuClose();
                generateMenu();
                setSelectedOrderFlowIndx(null);
              }}
              classes={{
                root:
                  classes.listItemRoot +
                  (prop.upgradeToPro
                    ? " " + classes.listItemRootUpgradeToPro
                    : ""),
                selected: classes.listItemSelected,
              }}
              target="_blank"
              selected={prop.upgradeToPro === true}
            >
              {textContent}
            </ListItem>
          );
        } else {
          return (
            <>
              {!prop?.accordian && (
                <ListItem
                  key={key}
                  component={Link}
                  onClick={() => {
                    handleMenuClose();
                    generateMenu();
                    setSelectedOrderFlowIndx(null);
                  }}
                  to={prop.layout + prop.path}
                  classes={{
                    root:
                      classes.listItemRoot +
                      (prop.upgradeToPro
                        ? ' ' + classes.listItemRootUpgradeToPro
                        : ''),
                    selected: classes.listItemSelected,
                  }}
                  selected={
                    location.pathname === prop.layout + prop.path ||
                    prop.upgradeToPro === true
                  }
                >
                  {textContent}
                </ListItem>
              )}
              {prop?.accordian &&
                orderFlow?.map((order, iKey) => {
                  return (
                    <ListItem
                      key={iKey}
                      component={Link}
                      onClick={() => {
                        handleMenuClose();
                        // setSelectedOrderFlowIndx(order?.WorkCenterId);
                        setSelectedOrderFlowIndx(iKey);
                        //handleOrderViewhistory.push();
                      }}
                      classes={{
                        root:
                          classes.listItemRoot +
                          (prop.upgradeToPro
                            ? ' ' + classes.listItemRootUpgradeToPro
                            : ''),
                        selected: classes.listItemSelected,
                      }}
                      className="cursor-pointer"
                      selected={selectedOrderFlowIndx === iKey}
                      to={
                        prop.layout +
                        '/orderflow/' +
                        order.WorkCenterName?.replace(' ', '-').toLowerCase() +
                        '/' +
                        order.WorkCenterId
                      }
                    >
                      <Box
                        minWidth="2.25rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ForwardIcon}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          //eslint-disable-next-line no-useless-concat
                          className={classes['text' + 'primary']}
                        />
                      </Box>
                      {order?.WorkCenterName}
                    </ListItem>
                  );
                })}
            </>
          );
        }
      });
  };
  
  const customerCreateLinks = (routes) => {
    return routes.filter(route => (route.showInSidebar && route?.roles?.includes(`${currentUser()?.Role}`)))
      .map((prop, key) => {
        if (prop.divider) {
          return <Divider key={key} classes={{ root: classes.divider }} />;
        } else if (prop.title) {
          return (
            <Typography
              key={key}
              variant="h6"
              component="h6"
              classes={{ root: classes.title }}
              className="text-bold text-base bg-gray-50"
            >
              {prop.title}
            </Typography>
          );
        }

        let textContent = (
          <>
            <Box minWidth="2.25rem" display="flex" alignItems="center">
              {typeof prop.icon === 'string' ? (
                <Box
                  component="i"
                  className={prop.icon + ' ' + classes['text' + prop.iconColor]}
                />
              ) : null}
              {typeof prop.icon === 'object' ||
                typeof prop.icon === 'function' ? (
                <Box
                  component={prop.icon}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  className={classes['text' + prop.iconColor]}
                />
              ) : null}
            </Box>

            {prop.name}
          </>
        );
        if (prop.href) {
          return (
            <ListItem
              key={key}
              component={'a'}
              href={prop.href}
              onClick={() => {
                handleMenuClose();
                generateMenu();
                setSelectedOrderFlowIndx(null);
              }}
              classes={{
                root:
                  classes.listItemRoot +
                  (prop.upgradeToPro
                    ? ' ' + classes.listItemRootUpgradeToPro
                    : ''),
                selected: classes.listItemSelected,
              }}
              target="_blank"
              selected={prop.upgradeToPro === true}
            >
              {textContent}
            </ListItem>
          );
        } else {
          return (
            <>

              {!prop?.accordian &&
                <ListItem
                  key={key}
                  component={Link}
                  onClick={() => {
                    handleMenuClose();
                    generateMenu();
                    setSelectedOrderFlowIndx(null);
                  }}
                  to={prop.layout + prop.path}
                  classes={{
                    root:
                      classes.listItemRoot +
                      (prop.upgradeToPro
                        ? " " + classes.listItemRootUpgradeToPro
                        : ""),
                    selected: classes.listItemSelected,
                  }}
                  selected={
                    location.pathname === prop.layout + prop.path ||
                    prop.upgradeToPro === true
                  }
                >
                  {textContent}
                </ListItem>
              }
              {prop?.accordian && orderFlow?.map((order, iKey) => {
                  return <ListItem
                      key={iKey}
                      component={Link}
                      onClick={() => {
                        handleMenuClose();
                        // setSelectedOrderFlowIndx(order?.WorkCenterId);
                        setSelectedOrderFlowIndx(iKey);
                        //handleOrderViewhistory.push();
                      }}
                      classes={{
                        root:
                          classes.listItemRoot +
                          (prop.upgradeToPro
                            ? " " + classes.listItemRootUpgradeToPro
                            : ""),
                        selected: classes.listItemSelected,
                      }}
                      className="cursor-pointer"
                      selected={selectedOrderFlowIndx === iKey}
                      to={currentUser()?.CustomerId ? prop.layout + '/customer-orderflow/' + order.WorkCenterName?.replace(' ', '-').toLowerCase() + '/' + order.WorkCenterId : prop.layout + '/orderflow/' + order.WorkCenterName?.replace(' ', '-').toLowerCase() + '/' + order.WorkCenterId}
                    >
                      <Box minWidth="2.25rem" display="flex" alignItems="center">
                        <Box
                          component={ForwardIcon}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          //eslint-disable-next-line no-useless-concat
                          className={classes["text" + "primary"]}
                        />
                      </Box>
                      {order?.WorkCenterName}
                    </ListItem>
                   })
                  }

            </>
          );
        }
      });
  };
  let logoImage = (
    <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc} />
  );
  let logoObject =
    logo && logo.innerLink ? (
      <Link to={logo.innerLink} className={classes.logoLinkClasses}>
        {logoImage}
      </Link>
    ) : logo && logo.outterLink ? (
      <a href={logo.outterLink} className={classes.logoLinkClasses}>
        {logoImage}
      </a>
    ) : null;
  return (
    <>
      <Hidden mdDown implementation="css">
        <Drawer variant="permanent" anchor="left" open>
          <Box paddingBottom="1rem">{logoObject}</Box>
          <List classes={{ root: classes.listRoot }}>
            {currentUser()?.CustomerId ?
              customerCreateLinks(routes) : createLinks(routes)}
          </List>
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="css">
        <AppBar position="relative" color="default" elevation={0}>
          <Toolbar>
            <Container
              display="flex!important"
              justifyContent="space-between"
              alignItems="center"
              marginTop=".75rem"
              marginBottom=".75rem"
              component={Box}
              maxWidth={false}
              padding="0!important"
            >
              <Box
                component={MenuIcon}
                width="2rem!important"
                height="2rem!important"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
              />
              {logoObject}
              {dropdown}
            </Container>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          classes={{ paper: classes.menuPaper }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft="1.25rem"
            paddingRight="1.25rem"
            paddingBottom="1rem"
            className={classes.outlineNone}
          >
            {logoObject}
            <Box
              component={Clear}
              width="2rem!important"
              height="2rem!important"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenuClose}
            />
          </Box>
          <Box
            component={Divider}
            marginBottom="1rem!important"
            marginLeft="1.25rem!important"
            marginRight="1.25rem!important"
          />
          <Box paddingLeft="1.25rem" paddingRight="1.25rem">
            {input}
          </Box>
          <List classes={{ root: classes.listRoot }} className="mb-2">
            {createLinks(routes)}
          </List>
        </Menu>
      </Hidden>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
};

Sidebar.propTypes = {
  // this is the input/component that will be rendered on responsive
  // in our demo, we add this input component since the AdminNavbar
  // will not be visible on responsive mode
  input: PropTypes.node,
  // this is the dropdown/component that will be rendered on responsive
  // in our demo, it is the same with the dropdown from the AdminNavbar
  // since the AdminNavbar will not be visible on responsive mode
  dropdown: PropTypes.node,
  // NOTE: we recommend that your logo has the following dimensions
  // // 135x40 or 487x144 or a resize of these dimensions
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // this generates an anchor (<a href="href">..</a>) link
      // this is a link that is sent outside the app
      PropTypes.shape({
        // if this is set to true, than the link will have an absolute position
        // use wisely and with precaution
        upgradeToPro: PropTypes.bool,
        href: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.oneOfType([
          // this refers to icons such as ni ni-spaceship or fa fa-heart
          PropTypes.string,
          PropTypes.object,
        ]),
        iconColor: PropTypes.oneOf([
          "Primary",
          "PrimaryLight",
          "Error",
          "ErrorLight",
          "Warning",
          "WarningLight",
          "Info",
          "InfoLight",
        ]),
      }),
      // this generates a Link (<Link to="layout + path">..</Link>) link
      // this is a link that is sent inside the app
      PropTypes.shape({
        path: PropTypes.string,
        name: PropTypes.string,
        layout: PropTypes.string,
        component: PropTypes.func,
        icon: PropTypes.oneOfType([
          // this refers to icons such as ni ni-spaceship or fa fa-heart
          PropTypes.string,
          PropTypes.object,
        ]),
        iconColor: PropTypes.oneOf([
          "Primary",
          "PrimaryLight",
          "Error",
          "ErrorLight",
          "Warning",
          "WarningLight",
          "Info",
          "InfoLight",
        ]),
      }),
      // this is just a title without any action on it
      // you can think of it as a disabled link
      PropTypes.shape({
        title: PropTypes.string,
      }),
      // this is just a divider line
      PropTypes.shape({
        divider: true,
      }),
    ])
  ),
};
