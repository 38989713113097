import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { Chip } from "@mui/material";

const useStyles = makeStyles(componentStyles);

const InventoryWorkOrdersMaterialList = () => {
  const classes = useStyles();
  const [transactionsList, setTransactionsList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      id: 'OrderNumber', numeric: false, disablePadding: false, label: 'Order Number', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div>
            #{row.OrderNumber}
          </div>
        )
      }
    },
    { id: 'MaterialName', numeric: false, disablePadding: true, label: 'Material', dataType: 'string' },
    {
      id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div>
            {row.Quantity} {row.MaterialUnit}
          </div>
        )
      }
    },
    {
      id: 'PricePerUnit', numeric: false, disablePadding: false, label: 'Perice Unit', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div>
            {row.PricePerUnit}
          </div>
        )
      }
    },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
    {
      id: 'QuantityStatus', numeric: false, disablePadding: false, label: 'Quantity Status', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div>
            <Chip color={row.QuantityStatus === "Used" ? "primary" : row.QuantityStatus === "Remaining" ? "error" : "warning"} label={row.QuantityStatus} />
          </div>
        )
      }
    },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Create Date', dataType: 'date' },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let res = await dataService('get', 'InventoryWorkOrdersMaterialList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setTransactionsList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  let actionList = []

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Work Orders Materials"
          columns={columns}
          rows={transactionsList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
    </>
  );
};

export default InventoryWorkOrdersMaterialList;