import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme, Autocomplete, Box, Card, CardContent, CardHeader, Container, FormGroup, FormLabel, Grid, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CustomButton } from "components/Common/CustomButton";

const useStyles = makeStyles(componentStyles);

function CreateRequiredPerFile() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const PageTitle = id ? "Update required per file" : "Add required per file";

  const [materialList, setMaterialList] = useState([]);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [messageDialog, setMessageDialog] = useState({ show: false, type: "", message: "" });

  const [productFileList, setProductFileList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [requiredPerFileList, setRequiredPerFileList] = useState([]);

  useEffect(() => {
    handleFunction()
  }, [id]);

  const handleFunction = async () => {
    getProductList();
    getRequiredPerFileList();
    await getMaterialList();
      if (id) {
        fetchData();
      }
  }

  const getProductList = async () => {
    let res = await dataService('get', 'CommonList', `Query=products`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data
        setProductList(mList);
      }
    }
  }


  const fetchData = async () => {
    try {
      let res = await dataService("get", `InventoryMaterialsRequiredPerFileGet/${id}`);
      if (res.status === 200) {
        const stockData = res?.data;
        formik.setValues({
          MaterialId: stockData.MaterialId,
          MaterialName: stockData.MaterialName,
          Quantity: stockData.Quantity,
          ProductFileId: stockData.ProductFileId,
          ProductFileSize: stockData.FileSize,
          ProductId: stockData.ProductId,
          ProductName: stockData.ProductName
        });
        getProductFileList(stockData.ProductId, stockData.MaterialId);
      }
      else {
        setMessageDialog({ show: true, type: "error", message: res?.message });
      }
    } catch (e) {
      console.log(e)
    }
  };

  const getProductFileList = async (ProductIdVal, MaterialId) => {
    let res = await dataService('get', 'CommonList', `Query=productFilesByCustomers&Param1=${null}&Param2=${ProductIdVal}`);
    if (res.status === 200) {
      if (res?.data) {
        let mList = res?.data || [];
        const filteredList = mList.filter((file) => {
          const isDuplicate = requiredPerFileList.some(existingFile =>
            existingFile.MaterialId === MaterialId &&
            existingFile.ProductId === ProductIdVal &&
            existingFile.ProductFileId === file.ProductFileId
            );
          
          return !isDuplicate; 
      });
        setProductFileList(filteredList);
      }
    }
  }

  const getMaterialList = async () => {
    try {
      let res = await dataService("get", "CommonList", `Query=inventoryMaterial`);
      if (res.status === 200) {
        setMaterialList(res?.data || []);
      }
      else {
        setMaterialList([]);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const getRequiredPerFileList = async () => {
    try {
      let res = await dataService("get", "CommonList", `Query=requiredPerFile`);
      if (res.status === 200) {
        setRequiredPerFileList(res?.data || []);
      }
      else {
        setRequiredPerFileList([]);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const validationSchema = Yup.object({
    MaterialId: Yup.string().nullable().required("Material is required field"),
    ProductId: Yup.string().nullable().required("Product is required field"),
    ProductFileId: Yup.string().nullable().required("Product file is required field"),
    Quantity: Yup.number().required("Quantity is required field").min(1, "Quantity must be greater than 0"),
  });

  const formik = useFormik({
    initialValues: {
      MaterialId: null,
      MaterialName: "",
      ProductId: null,
      ProductName: "",
      ProductFileId: null,
      ProductFileSize: "",
      Quantity: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      setShowSubmitLoading(true);
      let reqData = { ...values };
      let res = await dataService("post", `InventoryMaterialsRequiredPerFileSave/${id ? id : 0}`, reqData);
      setShowSubmitLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialog({ show: true, type: "success", message: res.data.Message });
        if (!id) {
          setTimeout(() => history.push("inventory-material-required-per-file"), 500);
        }
      } else {
        setMessageDialog({ show: true, type: "error", message: res.message });
      }
    },
  });

  const getMaterialValue = (id) => {
    const item = materialList.find((opt) => {
      if (Number(opt.MaterialId) === Number(id)) return opt;
    });
    return item || null;
  };

  const getProductValue = (id) => {
    const item = productList.find((opt) => {
      if (Number(opt.ProductId) === Number(id)) return opt;
    });
    return item || null;
  };

  const getProductFileValue = (id) => {
    const item = productFileList.find((opt) => {
      if (Number(opt.ProductFileId) === Number(id)) return opt;
    });
    return item || null;
  };

  return (
    <>
      <Header />
      <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
        <Grid container>
          <Grid item xs={12} xl={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot }}>
            <Card classes={{ root: classes.cardRoot + " " + classes.cardRootSecondary }}>
              <CardHeader
                subheader={
                  <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box component={Typography} variant="h3" marginBottom="0!important">
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialog.show} message={messageDialog.message} type={messageDialog.type} />
                </Grid>
                <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}>
                  Required Per File Information
                </Box>
                <form onSubmit={formik.handleSubmit} className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Material</FormLabel>
                        <Autocomplete
                          disablePortal
                          id="MaterialId"
                          options={materialList}
                          fullWidth
                          getOptionLabel={(option) => option.Name}
                          name="MaterialId"
                          value={getMaterialValue(formik.values.MaterialId)}
                          onChange={(event, newValue) => {
                            formik.setValues({
                              ...formik.values,
                              MaterialId: newValue ? newValue.MaterialId : null,
                              MaterialName: newValue ? newValue.Name : "",
                              ProductId: null,
                              ProductName: "",
                              ProductFileId: null,
                              ProductFileSize: "",
                            })
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Material"
                              value={formik.values.MaterialId}
                              error={formik.touched.MaterialId && Boolean(formik.errors.MaterialId)}
                              helperText={formik.touched.MaterialId && formik.errors.MaterialId}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Product</FormLabel>
                        <>
                          <Autocomplete
                            id="required-page-ProductId"
                            disabled={formik.values.MaterialId ? false : true}
                            value={getProductValue(formik.values.ProductId)}
                            options={productList}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                formik.setValues({
                                  ...formik.values,
                                  ProductId: newValue ? newValue.ProductId : null,
                                  ProductName: newValue ? newValue.Name : "",
                                  ProductFileId: null,
                                  ProductFileSize: "",
                                })
                                getProductFileList(newValue.ProductId, formik.values.MaterialId);
                              } else {
                                formik.setValues({
                                  ...formik.values,
                                  ProductId: null,
                                  ProductName: "",
                                  ProductFileId: null,
                                  ProductFileSize: "",
                                })
                                setProductFileList([]);
                              }
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.Name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Product"
                                autoComplete="new-password"
                                error={formik.touched.ProductId && Boolean(formik.errors.ProductId)}
                                helperText={formik.touched.ProductId && formik.errors.ProductId}
                              />
                            )}
                          />
                        </>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Product File</FormLabel>
                        <>
                          <Autocomplete
                            id="required-page-ProductFileId"
                            value={getProductFileValue(formik.values.ProductFileId)}
                            options={productFileList}
                            onChange={(event, newValue) => {
                              formik.setValues({
                                ...formik.values,
                                ProductFileId: newValue ? newValue.ProductFileId : null,
                                ProductFileSize: newValue ? newValue.FileSize : "",
                              })
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.FileSize}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Product File"
                                autoComplete="new-password"
                                error={formik.touched.ProductFileId && Boolean(formik.errors.ProductFileId)}
                                helperText={formik.touched.ProductFileId && formik.errors.ProductFileId}
                              />
                            )}
                          />
                        </>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Quantity</FormLabel>
                        <TextField
                          id="Quantity"
                          name="Quantity"
                          variant="filled"
                          type="number"
                          value={formik.values.Quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.Quantity && Boolean(formik.errors.Quantity)}
                          helperText={formik.touched.Quantity && formik.errors.Quantity}
                          sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                        <CustomButton
                          onClick={() => formik.handleSubmit()}
                          className="bg-[#5e72e4] hover:bg-[#233dd2]"
                          loading={showSubmitLoading}
                          type="submit"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CreateRequiredPerFile;
