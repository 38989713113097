import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme, Autocomplete, Box, Card, CardContent, CardHeader, Container, FormGroup, FormLabel, Grid, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CustomButton } from "components/Common/CustomButton";

const useStyles = makeStyles(componentStyles);

function CreateStock() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const PageTitle = id ? "Update Stock" : "Add Stock";

  const [materialList, setMaterialList] = useState([]);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [messageDialog, setMessageDialog] = useState({ show: false, type: "", message: "" });

  useEffect(() => {
    getMaterialList();
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      if (id) {
        let res = await dataService("get", `InventoryStockGet/${id}`);
        if (res.status === 200) {
          const stockData = res?.data;
          formik.setValues({
            MaterialId: stockData.MaterialId,
            MaterialName: stockData.MaterialName,
            Quantity: stockData.Quantity,
          });
        }
        else {
          setMessageDialog({ show: true, type: "error", message: res?.message });
        }
      }
    } catch (e) {
      console.log(e)
    }
  };

  const getMaterialList = async () => {
    try {
      let res = await dataService("get", "CommonList", `Query=inventoryMaterial`);
      if (res.status === 200) {
        setMaterialList(res?.data || []);
      }
      else {
        setMaterialList([]);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const validationSchema = Yup.object({
    MaterialId: Yup.string().nullable().required("Material is required"),
    Quantity: Yup.number().required("Quantity is required").min(1, "Quantity must be greater than 0"),
  });

  const formik = useFormik({
    initialValues: {
      MaterialId: null,
      MaterialName: "",
      Quantity: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setShowSubmitLoading(true);
      let reqData = { ...values };
      let res = await dataService("post", `InventoryStockSave/${id ? id : 0}`, reqData);
      setShowSubmitLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialog({ show: true, type: "success", message: id ? "Stock updated successfully!" : "Stock created successfully!" });
        if (!id) {
          setTimeout(() => history.push("inventory-stocks"), 500);
        }
      } else {
        setMessageDialog({ show: true, type: "error", message: res.message });
      }
    },
  });
  const getMaterialValue = (id) => {
    const item = materialList.find((opt) => {
      if (Number(opt.MaterialId) === Number(id)) return opt;
    });
    return item || null;
  };

  return (
    <>
      <Header />
      <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
        <Grid container>
          <Grid item xs={12} xl={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot }}>
            <Card classes={{ root: classes.cardRoot + " " + classes.cardRootSecondary }}>
              <CardHeader
                subheader={
                  <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box component={Typography} variant="h3" marginBottom="0!important">
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialog.show} message={messageDialog.message} type={messageDialog.type} />
                </Grid>
                <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}>
                  Stock Information
                </Box>
                <form onSubmit={formik.handleSubmit} className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Material Name</FormLabel>
                        {materialList.length > 0 && (
                          <Autocomplete
                            disablePortal
                            id="MaterialId"
                            options={materialList}
                            fullWidth
                            getOptionLabel={(option) => option.Name}
                            name="MaterialId"
                            value={getMaterialValue(formik.values.MaterialId)}
                            onChange={(event, newValue) => {
                              formik.setValues({
                                ...formik.values,
                                MaterialId: newValue ? newValue.MaterialId : null,
                                MaterialName: newValue ? newValue.Name : "",
                              })
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Material"
                                value={formik.values.MaterialId}
                                error={formik.touched.MaterialId && Boolean(formik.errors.MaterialId)}
                                helperText={formik.touched.MaterialId && formik.errors.MaterialId}
                              />
                            )}
                          />
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Quantity</FormLabel>
                        <TextField
                          id="Quantity"
                          name="Quantity"
                          variant="filled"
                          type="number"
                          value={formik.values.Quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.Quantity && Boolean(formik.errors.Quantity)}
                          helperText={formik.touched.Quantity && formik.errors.Quantity}
                          sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                        <CustomButton
                          onClick={() => formik.handleSubmit()}
                          className="bg-[#5e72e4] hover:bg-[#233dd2]"
                          loading={showSubmitLoading}
                          type="submit"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CreateStock;
