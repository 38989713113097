import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme, Box, Card, CardContent, CardHeader, Container, FormGroup, FormLabel, Grid, Typography, TextField, Select, MenuItem, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CustomButton } from "components/Common/CustomButton";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";

const useStyles = makeStyles(componentStyles);

function CreateInventoryPurchaseOrder() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const PageTitle = id ? "Update purchase order" : "Add purchase order";

  const [materialList, setMaterialList] = useState([]);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [messageDialog, setMessageDialog] = useState({ show: false, type: "", message: "" });

  const [purchaseOrdersList, setPurchaseOrdersList] = useState([]);
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState(null);
  useEffect(() => {
    getMaterialList();
    if (id) {
      fetchData();
    }
  }, [id]);

  const getMaterialList = async () => {
    try {
      let res = await dataService("get", "CommonList", `Query=inventoryMaterial`);
      if (res.status === 200) {
        setMaterialList(res?.data || []);
      }
      else {
        setMaterialList([]);
      }
    } catch (e) {
      console.log(e)
    }
  };

  const fetchPurchaseOrders = async (id, currentValues) => {
    try {
      let res = await dataService('get', 'InventoryPurchaseOrdersList', `searchText=${""}&sortBy=${""}&sortOrder=${""}&page=${1}&offset=${10}&MaterialId=${id}`);
      
      if (res.status === 200) {
        setPurchaseOrdersList(res?.data || []);
        formik.setValues({
          ...currentValues,
          PricePerUnit : res?.data[0]?.PricePerUnit || 0,
        });
      }
    } catch (e) {
      console.log(e)
    }
  };

  console.log("purcjase...", purchaseOrdersList)

  const fetchData = async () => {
    try {
      let res = await dataService("get", `InventoryPurchaseOrderGet/${id}`);
      if (res.status === 200) {
        const purchaseData = res?.data;
        setPurchaseOrderDetail(purchaseData);
        formik.setValues({
          OrderDate: moment(purchaseData?.OrderDate).format("YYYY-MM-DD"),
          TotalAmount: purchaseData?.TotalAmount,
          Status: purchaseData?.Status,
          MaterialId: purchaseData?.MaterialId,
          MaterialName: purchaseData?.MaterialName,
          MaterialUnit: purchaseData?.MaterialUnit,
          Quantity: purchaseData?.Quantity,
          PricePerUnit: purchaseData?.PricePerUnit,
        });
      }
      else {
        setMessageDialog({ show: true, type: "error", message: res?.message });
      }
    } catch (e) {
      console.log(e)
    }
  };

  const validationSchema = Yup.object({
    MaterialId: Yup.string().nullable().required("Material is required field"),
    Quantity: Yup.number().nullable().required("Quantity is required field").min(1, "Quantity must be greater than 0"),
    PricePerUnit: Yup.number().nullable().required("Price per unit is required field").min(1, "Price per unit must be greater than 0"),
    OrderDate: Yup.string().nullable().required("Order date is required field"),
  });

  const getMaterialValue = (id) => {
    const item = materialList.find((opt) => {
      if (Number(opt.MaterialId) === Number(id)) return opt;
    });
    return item || null;
  };

  const formik = useFormik({
    initialValues: {
      MaterialId: null,
      MaterialName: "",
      MaterialUnit: "",
      Quantity: "",
      PricePerUnit: "",
      OrderDate: null,
      TotalAmount: "",
      Status: "Pending",
    },
    validationSchema,
    onSubmit: async (values) => {
      setShowSubmitLoading(true);
      let reqData = { ...values };
      let res = await dataService("post", `InventoryPurchaseOrderSave/${id ? id : 0}`, reqData);
      setShowSubmitLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialog({ show: true, type: "success", message: res.data.Message });
        if (!id) {
          setTimeout(() => history.push("inventory-purchase-orders"), 500);
        }
      } else {
        setMessageDialog({ show: true, type: "error", message: res.message });
      }
    },
  });

  useEffect(() => {
    if (formik.values.Quantity && formik.values.PricePerUnit) {
      const totalAmount = formik.values.Quantity * formik.values.PricePerUnit;
      formik.setFieldValue('TotalAmount', totalAmount);
    }
    else {
      formik.setFieldValue('TotalAmount', 0);
    }
  }, [formik.values.Quantity, formik.values.PricePerUnit]);

  return (
    <>
      <Header />
      <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
        <Grid container>
          <Grid item xs={12} xl={12} component={Box} marginBottom="3rem" classes={{ root: classes.gridItemRoot }}>
            <Card classes={{ root: classes.cardRoot + " " + classes.cardRootSecondary }}>
              <CardHeader
                subheader={
                  <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box component={Typography} variant="h3" marginBottom="0!important">
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialog.show} message={messageDialog.message} type={messageDialog.type} />
                </Grid>
                <Box component={Typography} variant="h6" color={theme.palette.gray[600] + "!important"} paddingTop=".25rem" paddingBottom=".25rem" fontSize=".75rem!important" letterSpacing=".04em" marginBottom="1.5rem!important" classes={{ root: classes.typographyRootH6 }}>
                  Purchase Order Information
                </Box>
                <form onSubmit={formik.handleSubmit} className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Material</FormLabel>
                        <Autocomplete
                          disabled={id}
                          disablePortal
                          id="MaterialId"
                          options={materialList}
                          fullWidth
                          getOptionLabel={(option) => option.Name}
                          name="MaterialId"
                          value={getMaterialValue(formik.values.MaterialId)}
                          onChange={async (event, newValue) => {
                            if(newValue) {
                              const currentValues = {
                                ...formik.values,
                                MaterialId: newValue ? newValue.MaterialId : null,
                                MaterialName: newValue ? newValue.Name : "",
                                MaterialUnit: newValue ? newValue.Unit : "",
                              };
                              formik.setValues(currentValues);
                              if (newValue?.MaterialId) {
                                await fetchPurchaseOrders(newValue.MaterialId, currentValues);
                              }
                            }
                            else {
                              setPurchaseOrdersList([]);
                              formik.setValues({
                                ...formik.values,
                                MaterialId: null,
                                MaterialName: "",
                                MaterialUnit: "",
                                PricePerUnit: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Material"
                              value={formik.values.MaterialId}
                              error={formik.touched.MaterialId && Boolean(formik.errors.MaterialId)}
                              helperText={formik.touched.MaterialId && formik.errors.MaterialId}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Order Date</FormLabel>
                        <div className="bg-white custom-datepicker">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              // minDate={new Date(     )}
                              className="w-full"
                              name="OrderDate"
                              format="dd/MM/yyyy"
                              value={formik.values.OrderDate ? new Date(formik.values.OrderDate) : null}
                              onChange={e => {
                                formik.setFieldValue("OrderDate", moment(e).format("YYYY-MM-DD"));
                              }}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: formik.touched.OrderDate && Boolean(formik.errors.OrderDate),
                                  helperText: formik.touched.OrderDate && formik.errors.OrderDate,
                                },
                              }}

                            />
                          </LocalizationProvider>
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Quantity</FormLabel>
                        <TextField
                          id="Quantity"
                          name="Quantity"
                          variant="filled"
                          type="number"
                          value={formik.values.Quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.Quantity && Boolean(formik.errors.Quantity)}
                          helperText={formik.touched.Quantity && formik.errors.Quantity}
                          sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Price Per Unit {formik.values.MaterialUnit ? `(${formik.values.MaterialUnit})` : ""}</FormLabel>
                        <TextField
                          disabled={purchaseOrdersList.length > 0}
                          id="PricePerUnit"
                          name="PricePerUnit"
                          variant="filled"
                          type="number"
                          value={formik.values.PricePerUnit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.PricePerUnit && Boolean(formik.errors.PricePerUnit)}
                          helperText={formik.touched.PricePerUnit && formik.errors.PricePerUnit}
                          sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Total Amount</FormLabel>
                        <TextField
                          disabled
                          id="TotalAmount"
                          name="TotalAmount"
                          variant="filled"
                          type="number"
                          value={formik.values.TotalAmount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.TotalAmount && Boolean(formik.errors.TotalAmount)}
                          helperText={formik.touched.TotalAmount && formik.errors.TotalAmount}
                          sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <Select value={formik.values.Status} onChange={e => formik.setFieldValue("Status", e.target.value)}>
                          <MenuItem value={'Pending'}>Pending</MenuItem>
                          <MenuItem
                            disabled={
                              formik.values.OrderDate
                                ? moment(formik.values.OrderDate).isAfter(moment(), 'day')
                                : false
                            }
                            value={'Fulfilled'}>Fulfilled</MenuItem>
                        </Select>

                        {formik.values.OrderDate && moment(formik.values.OrderDate).isAfter(moment(), 'day') && (
                          <Typography sx={{fontSize : "12px !important"}} color="error" mt={0.5}>
                            "Fulfilled" cannot be selected because the order date is in the future.
                          </Typography>
                        )}

                      </FormGroup>
                    </Grid>
                  </Grid>

                  {!(id && purchaseOrderDetail?.Status === 'Fulfilled') ?
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                          <CustomButton
                            onClick={() => formik.handleSubmit()}
                            className="bg-[#5e72e4] hover:bg-[#233dd2]"
                            loading={showSubmitLoading}
                            type="submit"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    : null}
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CreateInventoryPurchaseOrder;
