import React, { useState, useEffect } from "react";
import { Popover, List, ListItem, ListItemText, Typography, Box, Badge, IconButton, Divider } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { dataService } from "services/data-service";
import { Notifications } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { formatMessage } from "utils/helper";
import OrdersCommentsDialog from "./OrdersCommentsDialog";
import useSocket from "hooks/useSocket";

export default function NotificationPopover() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [viewDetailDialog, setViewDetailDialog] = useState({
        isVisible: false,
        data: null,
        extraData: null
      });
      const [isReload, setIsReload] = useState(false);
      const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const unreadNotificationCount = useSocket('unreadNotificationCount', 0);
    const [localUnreadNotificationCount, setLocalUnreadNotificationCount] = useState(unreadNotificationCount);

    useEffect(() => {
        setLocalUnreadNotificationCount(unreadNotificationCount);
    }, [unreadNotificationCount]);
    
    const open = Boolean(anchorEl);

    const fetchNotifications = async () => {
        try {
            let response = await dataService('get', 'NotificatiosList', `searchText=${""}&sortBy=${"desc"}&sortOrder=${"CreatedAt"}&page=${1}&offset=${10}
         &userId=${currentUser()?.UserId}&showUnreadOnly=${0}`);
            setNotifications(response.data);
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        }
    };

    // const getNotificationsCount = async () => {
    //     try {
    //         let response = await dataService('get', `UnreadNotificatiosCount/${currentUser()?.UserId}`, null);
    //         setUnreadCount(response.data?.[0]?.UnreadNotificationsCount);
    //     } catch (error) {
    //         setUnreadCount(0);
    //         console.error("Failed to fetch notifications count:", error);
    //     }
    // };

    useEffect(() => {
        if (open) {
            fetchNotifications();
        }
    }, [open]);

    const handleOpen = (event) => {
        notificationsUnreadStatusUpdate();
        setAnchorEl(event.currentTarget);
    };

    const  notificationsUnreadStatusUpdate = async () => {
        try {
            let response = await dataService('get', `NotificationsUnreadStatusUpdate/${currentUser()?.UserId}`, '');
            if (response.status === 200) {
                setLocalUnreadNotificationCount(0);
            }
        } catch (error) {
            console.error("Failed to update notification status:", error);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewAll = () => {
        handleClose();
        history.replace('/admin/notifications');
    };

    const handleOpenCommnets =  async (row) => {
        setAnchorEl(null);
        try {
          let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
          if (res.status === 200 && res?.data) {
            setViewDetailDialog({
              isVisible: true,
              data: res?.data,
              extraData: {CustomerName : row?.CustomerName, FileName  :  row?.FileName, OrderNumber : row?.OrderNumber, OrderId : row?.OrderId}
            });
          }
        } catch (error) {
            
        }
      }

    return (
        <>
            <IconButton color="inherit" onClick={handleOpen}>
                <Badge badgeContent={localUnreadNotificationCount} color="error"
                    sx={{
                        "& .MuiBadge-badge": {
                            fontSize: "0.7rem",
                            height: "20px",
                            minWidth: "16px",
                            padding: "0 4px",
                        },
                    }}  

                >
                    <NotificationsIcon color="white" style={{ width: 25, height: 30, marginRight: 0 }} />
                </Badge>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Box
                    sx={{
                        maxWidth: 500,
                        minWidth: 300,
                        maxHeight: 400,
                        overflow: "auto",
                        backgroundColor: "background.paper",
                        borderRadius: "8px",
                        paddingX: 2,
                        paddingY: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', paddingBottom: 2, flexDirection: "row", justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                            Notifications
                        </Typography>
                        <Typography
                            color="primary"
                            onClick={handleViewAll}
                            sx={{ fontWeight: 'bold', fontSize: '0.875rem', cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            View All
                        </Typography>
                    </Box>


                    <List sx={{ padding: 0, maxHeight : 300, overflow : "auto",  }}>
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <React.Fragment key={notification.NotificationsId}>
                                    <ListItem button sx={{ paddingY: 0, borderRadius: 1, "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" } }}>
                                        <ListItemText
                                            primary={<Typography onClick={() => handleOpenCommnets(notification)} sx={{lineHeight : 2}} variant="body1" color="textPrimary">Order: <span style={{ fontWeight: "bold" }}> #{notification?.OrderNumber}</span></Typography>}
                                            secondary={
                                                <Typography onClick={() => handleViewAll()} variant="body2" color="textPrimary" sx={{lineHeight : 1.7}}>{formatMessage(notification.Message, currentUser()?.UserId)}</Typography>
                                            }
                                            primaryTypographyProps={{
                                                fontWeight: "bold",
                                                fontSize: "0.1rem",
                                            }}
                                            secondaryTypographyProps={{
                                                fontSize: "0.75rem",
                                            }}
                                        />
                                        <IconButton edge="end" size="small" sx={{ color: "gray" }}>
                                            <Notifications sx={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ padding: 2, textAlign: "center" }}>
                                No notifications.
                            </Typography>
                        )}
                    </List>
                </Box>

            </Popover>

            {viewDetailDialog?.isVisible &&
                <OrdersCommentsDialog
                    isOrderFlow={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setIsReload={setIsReload}
                    isReload={isReload}
                    open={viewDetailDialog}
                    setOpen={setViewDetailDialog}
                    onClose={() => {
                        setViewDetailDialog({ data: null, isVisible: false, extraData: null })
                    }} />}
        </>
    );
}
