import React, { useState } from 'react';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { dataService } from 'services/data-service';
import { CustomButton } from 'components/Common/CustomButton';
import { LoadingIcon } from 'components/Icons/Loading';

function GECheckItemDialog({ onClose, open, setIsReload, isReload, disabled = false }) {
    const [checkList, setCheckList] = useState(open?.data);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false);

    const isDataEqual = (oldData, newData) =>
        oldData.every((item, index) => item.Checked === newData[index].Checked);

    const handleClose = () => {
        if (!isDataEqual(open?.data, checkList)) {
            setIsReload(!isReload);
        }
        onClose(false);
    };

    const handleCheckboxChange = async (selectedData, checked) => {
        setShowSubmitLoading(true);
        try {
            const res = await dataService('post', `GoodsEntryCheckItemStatusSave/${selectedData?.GoodsEntryCheckItemStatusId || 0}`, {
                GoodsEntryId: open?.extraData?.GoodsEntryId,
                GoodsEntryCheckItemMasterId: selectedData?.GoodsEntryCheckItemMasterId,
                Checked: checked ? 1 : 0,
            });

            if (res?.status === 200) {
                setCheckList(prevCheckList =>
                    prevCheckList.map(item =>
                        Number(item.GoodsEntryCheckItemMasterId) === Number(selectedData.GoodsEntryCheckItemMasterId)
                            ? { ...item, Checked: checked ? 1 : 0,  CheckedBy : res?.data?.CheckedBy ? res?.data?.CheckedBy : item?.CheckedBy }
                            : item
                    )   
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowSubmitLoading(false);
        }
    };

    return (
        <Dialog onClose={handleClose} fullWidth maxWidth="md" open={open?.isVisible}>
            <div className={`${open?.extraData?.IsCheckItemsChecked ? "bg-green-500 border-green-500" : "bg-red-500 border-red-500"} border-2 shadow sm:rounded-t rounded-md flex items-center justify-between`}>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <span className="text-xl">GE Check List - #{open?.extraData?.GoodsEntryNumber}</span><br />
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className="py-2 ml-2">
                <Box>
                    <Grid container spacing={0.5}>
                        {checkList.map(item => (
                            <Grid item xs={12} key={item.GoodsEntryCheckItemStatusId}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            checked={item.Checked === 1}
                                            onChange={event => handleCheckboxChange(item, event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Box>
                                            <Typography>{item?.CheckItem} {item?.CheckedBy ? <span style={{ fontSize: 11 }}>({item?.CheckedBy})</span> : ""}</Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Ok" onClick={handleClose} />
            </DialogActions>

            {showSubmitLoading && <LoadingIcon overlay />}
        </Dialog>
    );
}

export default GECheckItemDialog;
