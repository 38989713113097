import { useState, useEffect, useRef } from 'react';
import { currentUser } from 'services/user-service';
import { io } from 'socket.io-client';

const useSocket = (eventName, defaultValue = 0) => {
    const [data, setData] = useState(defaultValue);
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    
        socketRef.current.on("connect", () => {
    
            socketRef.current.on(eventName, (count) => {
                setData(count);
            });
    
            const userId = currentUser()?.UserId; 
            if (userId) {
                socketRef.current.emit("joinRoom", userId);
    
                socketRef.current.emit(eventName, userId);
            }
        });
    
        socketRef.current.on("disconnect", () => {
        });
    
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    return data;
};

export default useSocket;
