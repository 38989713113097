import React, { useState } from 'react';
import { CustomButton } from "components/Common/CustomButton";
// import { VerificationCodeInput } from "components/Common/CodeInput";
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
// import Box from "@mui/material/Box";
// import FilledInput from "@mui/material/FilledInput";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import { checkEmptyValidation } from 'services/validation-service';
import { dataService } from 'services/data-service';
import MessageBar from "../MessageBar/MessageBar";
import { FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { store } from "generic/store";
import { updateVerificationCodeStore } from "../../generic/store";
import { Close } from '@mui/icons-material';
import VerificationCodeInput from 'components/Common/CodeInput';

export const showVerificationDialog = (title, openDialog, onClose, onSubmit) => {
    store?.dispatch(updateVerificationCodeStore({
        title,
        openDialog,
        onClose,
        onSubmit
    }));
};

export default function VerifyOTPDialog() {
    let verifyInput = React.createRef();
    const { title, openDialog, onClose, onSubmit } = useSelector(state => state?.verificationCodeStore?.verificationCode);

    const [verificationCode, setVerificationCode] = useState(null);
    const [showSendLoading, setShowSendLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const dispatch = useDispatch(); // to update store values
    // Usef defined states
    const verificationCodeModelInitialState = {
        VerificationCode: ''
    }
    const [verificationCodeModel, setVerificationCodeModel] = useState(verificationCodeModelInitialState);

    // Usef defined states
    const validateModelInitialState = {
        VerificationCode: { message: '', touched: false },
        IsValidationError: false
    }
    const [verificationCodeValidateModel, setVerificationCodeValidateModel] = useState(validateModelInitialState);
    const [messageSentCounter, setMessageSentCounter] = useState(0);


    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const verificationStoreInitialize = () => {
        setMessageDialoge({ show: false, type: '', message: '' })
        setVerificationCodeModel(verificationCodeModelInitialState);
        setVerificationCodeValidateModel(validateModelInitialState);
        setIsSent(false)

        dispatch(updateVerificationCodeStore({
            title: '',
            openDialog: false,
            onClose: undefined,
            onSubmit: undefined
        }))
    }

    const handleClose = () => {
        verificationStoreInitialize();
        onClose();

    };

    const handleChange = (id, value) => {

        switch (id) {

            case 'VerificationCode':
                setVerificationCodeModel({ ...verificationCodeModel, VerificationCode: value });
                setVerificationCodeValidateModel({ ...verificationCodeValidateModel, VerificationCode: { message: checkEmptyValidation(value, 'Verification Code'), touched: true } });
                break;
        }
    }


    const sendVerificationCodeHandle = async () => {
        setIsSent(false);
        setMessageSentCounter(messageSentCounter + 1);
        setShowSendLoading(true);
        setMessageDialoge({ show: false, type: 'error', message: '' });
        let res = await dataService('post', `SendVerificationCode`, {});
        setShowSendLoading(false);
        if (res && (res.status === 200 || res.status === 304)) {
            setIsSent(true);
            setVerificationCode(res?.data?.VerificationCode);
        }
        else {
            setVerificationCode(null);
            setMessageDialoge({ show: true, type: 'error', message: 'ERROR! Verification code not sent.' });
        }
    }

    const handleSubmitVerify = async (e, codeVal) => {
        setMessageDialoge({ show: false, type: '', message: '' });
        if(codeVal){
            setTimeout(() => {
                VerifyCode(codeVal)    
            }, 400);
            
        }
        else{
            VerifyCode(codeVal)
        }
    }

    const VerifyCode = async (codeVal) => {
        if (!checkValidations(codeVal)) {
            if(verificationCodeModel.VerificationCode === verificationCode || codeVal === verificationCode)
            {
                //setIsOTPVerified(true)
                verificationStoreInitialize();
                onSubmit();
            }
            else {
                if(codeVal?.length === 6){
                    let verificationRes = await dataService('post', `verifyAuthCode`, {VerificationCode: codeVal});
                    if(verificationRes?.status === 200){
                        verificationStoreInitialize();
                        onSubmit();
                    }
                    else{
                        setMessageDialoge({ show: true, type: 'error', message: 'Incorrect verification code' });
                    }
                }
                
            }
        }
    }


    const checkValidations = (codeVal) => {
        let verCode = verificationCodeModel.VerificationCode;
        if(codeVal){
            verCode = codeVal;
        }

        verCode = verCode?.length === 6 ? verCode : '';
        let validateErrors = {
            VerificationCode: { message: checkEmptyValidation(verCode, 'Verification code'), touched: true },
            IsValidationError: false
        }
        if (
            validateErrors.VerificationCode.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setVerificationCodeValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog open={openDialog} disableBackdropClick onClose={handleClose} aria-labelledby="form-dialog-title">

            <div className='bg-blue-500 border-blue-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">{title}</span><br />
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent className="pb-0">
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <MessageBar className="mb-0" show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FormGroup>
                            <FormLabel>Verification Code</FormLabel>
                            <Grid>
                                <VerificationCodeInput
                                    ref={verifyInput}
                                    className="custom-class"
                                    onChange={val => handleChange('VerificationCode', val)}
                                    onComplete={val => handleSubmitVerify(null, val)}
                                     />
                            </Grid>
                            <FormHelperText className="text-red-500">{verificationCodeValidateModel.VerificationCode.message !== '' && verificationCodeValidateModel.VerificationCode.touched ? verificationCodeValidateModel.VerificationCode.message : ''}</FormHelperText>
                            <CustomButton className="mt-3 bg-green-600 border-green-600 w-32" size="medium" loading={showSendLoading} label={`${messageSentCounter > 0 ? 'Resend' : 'Send'}`} onClick={sendVerificationCodeHandle} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="pt-0">
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Verify" onClick={(e) => {handleSubmitVerify(e, verificationCodeModel?.VerificationCode)}} />
            </DialogActions>
        </Dialog>
    );
}
