import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import CommonOrdersTable from "components/Tables/CommanOrdersTable";
import { dataService } from "services/data-service";
import ViewOrderDialog from "../../../../components/dialoges/ViewOrders/ViewOrderDialog";
import { useHistory } from 'react-router-dom';
import {Edit as EditIcon, Visibility as VisibilityIcon, FileCopy, Link as LinkIcon, Print} from "@mui/icons-material"
import { GoodsEntryIcon } from "components/Icons/CustomIcons";
import ViewOrdersGEDialog from "components/dialoges/ViewOrdersGEDialog";
import MapOrderDialog from "components/dialoges/MapOrderDialog";
import PrintOrderForm from "components/dialoges/PrintOrder/PrintOrderForm";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import OrderFlowListDialog from "components/dialoges/OrderFlowListDialog";
import { OrderFlowIcon } from "components/Icons/CustomIcons";
import CharacterBox from "components/Common/CharacterBox";
import ApproxDeliveryDateIcon from "components/Common/ApproxDeliveryDateIcon";
import OrdersCommentsDialog from "components/dialoges/OrdersCommentsDialog";

const useStyles = makeStyles(componentStyles);

const RunningOrders = () => {
  const classes = useStyles();
  const history = useHistory();
  const [runningOrderList, setRunningOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [customerSearchText, setCustomerSearchText] = useState('');
  const [fileSearchText, setFileSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('OrderDate');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);

  const [orderFilter, setOrderFilter] = useState({
    TagInterlock: null,
    TagZigZag: null,
    BindingsDieCut: null,
    BindingsFoil: null,
    BindingsTagPrinted: null
  });

  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const [printOrderFormData, setPrintOrderFormData] = useState(null);
  const [openPrintOrderForm, setOpenPrintOrderForm] = useState(null);

  const [goodsDetailDialogData, setGoodsDetailDialogData] = useState(false);
  const [openGoodsDetailDialog, setOpenGoodsDetailDialog] = useState(false);

  const [openShowFlowListView, setOpenShowFlowListView] = useState(null);
  const [flowStepListOrder, setFlowStepListOrder] = useState(null);

  const [openMaporder, setOpenMaporder] = useState(false);
  const [mappingData, setMappingData] = useState(null);
  const [isReload, setIsReload] = useState(true);

  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [loadingRows, setLoadingRows] = useState({});
  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData: null
  });
  // const [date, setDate] = useState(null)
  // const [selectedOrderId, setSelectedOrderId] = useState(null)

  // const handleClick = (row) => {
  //   getApproxyDeliveryDate(row?.OrderId)
  // };

  // const getApproxyDeliveryDate = async (orderId) => {
  //   if (orderId !== selectedOrderId) {
  //     let res = await dataService('get', `ApproxDeliveryDate/${orderId}`);
  //     setSelectedOrderId(orderId)
  //     setDate(res?.data?.[0]?.ApproxDeliveryDate)
  //   }

  // }


  const columns = [
    {
      id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order Number', Component: ({ row }) => {
        return (
          <ApproxDeliveryDateIcon data={row} />
        )
      }
    },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    { id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'string' },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'Client', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    {
      id: 'InSince', numeric: false, disablePadding: false, label: 'In Since', dataType: 'component', Component: ({row}) => {
        return (
          <>
            <CharacterBox classNames={`text-xl font-extrabold cursor-pointer
                ${row?.InSinceColor === 'green' ? "bg-green-600" :
                  row?.InSinceColor === 'yellow' ? "bg-yellow-600" :
                    row?.InSinceColor === 'red' ? "bg-red-600" :
                      row?.StartDate ? 'bg-green-100' : "bg-white"}`} character={row?.InSince} />
          </>
        )
      }
    },
    { id: 'HoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' },
  ];

  useEffect(() => {
    const fetchData = async() => {
      if (localStorage.getItem('products')) {
        let productsAr = JSON.parse(localStorage.getItem('products'));
        setProductList(productsAr);
      }
      else {
        let res = await getProductList();
        setProductList(res?.data);
      }
    }
   fetchData()
  }, [])

  const getProductList = async () => {
    let res = await dataService('get', 'ProductList', null);
    if (res?.data) {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
    }
  }
  useEffect(() => {
    const fetchOrderData = async() => {
    setIsLoading(true);
    let res = await dataService('get', 'RunningOrderList', `produtID=${selectedProductId || 0}&customerSearchText=${customerSearchText}&fileSearchText=${fileSearchText}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}
    &TagInterlock=${orderFilter?.TagInterlock}&TagZigZag=${orderFilter?.TagZigZag}&BindingsDieCut=${orderFilter?.BindingsDieCut}&BindingsFoil=${orderFilter?.BindingsFoil}&BindingsTagPrinted=${orderFilter?.BindingsTagPrinted}`);
    setRunningOrderList(res?.data);
    setTotalRecord(res?.totalRecord);
    setTotalQuantity(res?.totalQuantity)
    setIsLoading(false);
    }

    fetchOrderData()
  }, [page, searchText, customerSearchText, fileSearchText, isReload, order, orderBy, offset, selectedProductId, orderFilter])


  const viewAction = (OrderDetail) => {

    setOpen(true);
    setOrderId(OrderDetail.OrderId);
  };

  const editAction = (row) => {
    history.push(`/admin/order/${row.OrderId}`)
  };

  const makeCopyAction = (OrderDetail) => {
    history.push(`/admin/order-copy/${OrderDetail.OrderId}`)
  }

  const viewGoodsDetailAction = (row) => {
    setGoodsDetailDialogData(row)
    setOpenGoodsDetailDialog(true);
  };

  const handleMapOrderDialog = (row) => {
    setOpenMaporder(true);
    setMappingData(row);
  };

  const handlePrintOrderFormDialog = (row) => {
    setOpenPrintOrderForm(true);
    setPrintOrderFormData(row.OrderId);
  };


  const handleSelect = async (ProductId) => {
    setSelectedProductId(parseInt(ProductId));
  }

  const FlowSetpListAction = (OrderDetail) => {
    setOpenShowFlowListView(true);
    setFlowStepListOrder(OrderDetail);
  };


  let actionList = [
    { label: 'View', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: editAction },
    { label: 'Make Copy', icon: FileCopy, iconColor: 'pink-500', action: makeCopyAction },
    { label: 'Goods Detail', icon: GoodsEntryIcon, iconColor: 'white', action: viewGoodsDetailAction },
    { label: 'Map Order', icon: LinkIcon, iconColor: 'blue-500', action: handleMapOrderDialog },
    { label: 'FlowSteps', icon: OrderFlowIcon, iconColor: 'yellow-500', action: FlowSetpListAction },
    { label: 'Print Order Form', icon: Print, iconColor: 'blue-500', action: handlePrintOrderFormDialog },

  ]

  const handleRowClick = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.OrderId]: true
    }));
    try {
      let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
      if (res.status === 200 && res?.data) {
        setViewDetailDialog({
          isVisible: true,
          data: res?.data,
          extraData: row
        });
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedProductId}>
          <FormControlLabel value={0} className="z-10" control={<Radio color="default" />} label="All" />
          {productList?.map((product, index) => {
            return <FormControlLabel className="z-10" key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} />
          })}
        </RadioGroup>

        <CommonOrdersTable
          title="Running Orders"
          columns={columns}
          rows={runningOrderList}
          page={page - 1}
          total={totalRecord}
          totalQuantity={totalQuantity}
          pagedata={setPage}
          setSearchText={setSearchText}
          setCustomerSearchText={setCustomerSearchText}
          setFileSearchText={setFileSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          offset={offset}
          actions={actionList}
          loading={isLoading}
          runningOrder={true}
          setOrderFilter={setOrderFilter}
          orderFilter={orderFilter}
          loadingRows={loadingRows}
          handleRowClick={handleRowClick}
          uniqueKey="OrderId"
        />
      </Container>

      {flowStepListOrder && openShowFlowListView &&
        <OrderFlowListDialog open={openShowFlowListView} viewDialogData={{ title: "Test" }} onClose={setOpenShowFlowListView} OrderDetail={flowStepListOrder} />
      }


      {orderId && open &&
        <ViewOrderDialog open={open} onClose={setOpen} OrderId={orderId} setIsReload={setIsReload} isReload={isReload} />
      }

      {openGoodsDetailDialog &&
        <ViewOrdersGEDialog onClose={setOpenGoodsDetailDialog} open={openGoodsDetailDialog} OrderDetail={goodsDetailDialogData} />
      }

      {mappingData && openMaporder &&

        <MapOrderDialog from={'orders'} mapTo={'GoodsEntry'} open={openMaporder} onClose={setOpenMaporder} MappingData={mappingData} setIsReload={setIsReload} isReload={isReload} />
      }

      {printOrderFormData && openPrintOrderForm &&
        <PrintOrderForm open={openPrintOrderForm} onClose={setOpenPrintOrderForm} OrderId={printOrderFormData} />
      }
       {viewDetailDialog?.isVisible &&
        <OrdersCommentsDialog
          isOrderFlow={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewDetailDialog}
          setOpen={setViewDetailDialog}
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData: null })
          }} />}
    </>
  );
};

export default RunningOrders