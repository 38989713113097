import React from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, InputAdornment, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import { StyledEngineProvider } from '@mui/material/styles';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
// @mui/icons-material components
//import MoreVert from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';

// @mui/icons-material components
import { useHistory } from 'react-router-dom';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import ThreeDotsMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import { LoadingIcon } from 'components/Icons/Loading';
import { currencyFormat } from 'services/data-service';
import { Search, Visibility } from '@mui/icons-material';
//import moment from "moment";


const useStyles = makeStyles(componentStyles);

const CommonTable = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let timeout = 0;
  const {
    view,
    title,
    rows,
    customHeaderTitle,
    page,
    total,
    pagedata,
    searchText,
    setSearchText,
    order,
    orderBy,
    orderData,
    orderByData,
    columns,
    editAction,
    actions,
    setOffset,
    offset,
    loading,
    component,
    pagination = true,
    viewURL, loadingRows, handleRowClick, onSigleClick, uniqueKey, preOrder, setOrderFilter, orderFilter
  } = props;
  const headCells = columns;
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [seachTextInputVal, setSeachTextInputVal] = React.useState(searchText);

  const handleEditClick = (row) => {
    editAction(row);
  };

  // const handleViewClick = (row) => {
  //   if (actions?.length > 0) {
  //     let viewAct = actions?.find((ac) => ac.label === 'View');
  //     if (viewAct) {
  //       let viewAction = viewAct?.action;
  //       viewAction(row);
  //     }
  //   }
  // };

  //Handle Page changes
  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  const handleViewClick = (row) => {
    let action = actions?.find(rs => { return rs?.label === 'View' })
    action.action(row)
  };


  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10));
    pagedata(1);
  };

  //Search data
  const handleSearchInput = (e) => {
    if (timeout) clearTimeout(timeout);
    setSeachTextInputVal(e.target.value);
    timeout = setTimeout(() => {
      setSearchText(e.target.value);
      pagedata(1);
    }, 400);
  };

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {viewURL === "pre-order" ?
            <TableCell
              key={200}
              align={'center'}
              padding={'none'}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              view
            </TableCell> : null}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {actions?.length > 0 && (
            <TableCell
              key={100}
              align={'center'}
              padding={'none'}
              classes={{
                root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
              }}
            >
              Action
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;

    const displayRowData = (row, headCellData) => {
      let returnStr = null;

      if (headCellData?.Component) {
        const Component = headCellData?.Component;
        return <Component row={row} headCellData={headCellData} />;
      }
      if (headCellData?.dataType === 'string') {
        return row[headCellData.id];
      } else if (headCellData?.dataType === 'date') {
        return row[headCellData.id]
          ? moment(row[headCellData.id]).format('D-MM-YYYY')
          : '';
      } else if (headCellData.dataType === 'date' && headCellData.dateFromNow) {
        moment(row[headCellData.id]).fromNow(true);
      }
      else if (headCellData.dataType === 'currency') {
        return currencyFormat(row[headCellData.id]);
      }
      return returnStr;
    };

    return (
      <TableBody className="relative">
        {rows?.map((rowData, index) => {
          return (
            // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
            <TableRow
              onClick={() => onSigleClick && onSigleClick(rowData)}
              onDoubleClick={() => handleRowClick && handleRowClick(rowData)}
              key={index}
              className={`cursor-pointer ${selectedRowIndex === index ? 'bg-blue-100' : ''
                }`}
            >
              {loadingRows && loadingRows[rowData[uniqueKey]] ? (
                <TableCell colSpan={columns.length + 2} align="center">
                  <LoadingIcon />
                </TableCell>
              ) : (
                <>

                  {viewURL === "pre-order" ?
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      align="left"
                    >
                      <Box
                        aria-controls="simple-menu-1"
                        aria-haspopup="true"
                        onClick={(e) => { handleViewClick(rowData) }}
                        size="small"
                        component={Button}
                        className="p-0"
                        width="2rem!important"
                        height="2rem!important"
                        minWidth="2rem!important"
                        minHeight="2rem!important"
                      >
                        <Visibility fontSize="small" style={{ color: "#424242" }} />
                      </Box>
                    </TableCell> : null}
                  {headCells.map((headCell, hdIndex) => (
                    <TableCell
                      key={hdIndex}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          ' ' +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      <div>{displayRowData(rowData, headCell)}</div>
                      {/* {headCell.dataType === 'string' && rowData[headCell.id]} */}
                      {/* {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('Do MMMM YYYY') : ''} */}
                      {/* {headCell.dataType === 'date' &&
                  !headCell.dateFromNow &&
                  rowData[headCell.id]
                    ? moment(rowData[headCell.id]).format('D-MM-YYYY')
                    : ''}
                  {headCell.dataType === 'date' &&
                  headCell.dateFromNow &&
                  rowData[headCell.id]
                    ? moment(rowData[headCell.id]).fromNow(true)
                    : ''} */}
                    </TableCell>
                  ))}

                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="center"
                  >
                    {editAction && (
                      <Box
                        aria-controls="simple-menu-1"
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(rowData);
                        }}
                        size="small"
                        component={Button}
                        className='p-0'
                        width="2rem!important"
                        height="2rem!important"
                        minWidth="2rem!important"
                        minHeight="2rem!important"
                      >
                        <EditIcon fontSize="large" className='w-5 h-5' style={{ color: theme.palette.gray[500] }} />
                      </Box>
                    )}

                    {actions?.length > 0 && (
                      <ThreeDotsMenu
                        data={rowData}
                        actions={actions}
                        index={index}
                        setSelectedRowIndex={setSelectedRowIndex}
                      />
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
        {rows?.length === 0 && !loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              Record Not Found
            </TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  EnhancedTableRows.propTypes = {
    // rows, page, total, pagedata, setSearchText
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array,
  };

  /*------------- Table Content End ------------*/

  const commonFormControlLabelSx = {
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px !important',
        sm: "14px !important"
      },
    },
  };

  const handleChangeOrderFilter = (event) => {
    const selectedValue = event.target.value;

    setOrderFilter(prevState => {
      if (prevState[selectedValue] === 1) {
        return {
          ...prevState,
          [selectedValue]: null
        };
      } else {
        return {
          Pending : null,
          Approved : null,
          Canceled : null,
          [selectedValue]: 1
        };
      }
    });
  };

  return (
    <StyledEngineProvider injectFirst>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <>
              <Grid
                spacing={2}
                container

              >
                {customHeaderTitle ?
                  customHeaderTitle()
                  :
                  <Grid item xs={12} sm={6} md={component ? 6 : 6} lg={component ? 3 : 3} >
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                    >
                      {title}
                    </Box>
                  </Grid>
                }
                {component && (
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    {component}
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={component ? 6 : 6} lg={component ? 3 : 6}>
                  <TextField
                    size='small'
                    id="name"
                    fullWidth
                    value={seachTextInputVal}
                    autoComplete="name"
                    placeholder={"Search"}
                    onChange={(e) => {
                      handleSearchInput(e);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-input:not(:last-child)': {
                        height: 35,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className="text-black" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {view && (
                  <Grid item xs={12} sm={component ? 6 : 12} md={component ? 6 : 12} lg={3}>
                    <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                      <Button
                        className="capitalize bg-[#5e72e4]"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          history.push(viewURL ? viewURL : view);
                        }}
                      >
                        Add {view}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {preOrder &&
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'center', sm: 'flex-end' },
                    alignItems: 'center',
                    gap: isMobile ? '5px' : '16px',
                    mb: isMobile ? "-25px" : "0px"

                  }}
                >
                  <FormGroup sx={{mb : "-1.5rem"}}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="Type"
                      value={Object.keys(orderFilter).find(key => orderFilter[key] === 1) || ''}
                      sx={{ p: 0 }}
                    >
                      <FormControlLabel
                        value="Pending"
                        control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                        label="Pending"
                        sx={{ ...commonFormControlLabelSx }}
                      />
                      <FormControlLabel
                        value="Approved"
                        control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                        label="Approved"
                        sx={{ ...commonFormControlLabelSx }}
                      />
                      <FormControlLabel
                        value="Canceled"
                        control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                        label="Canceled"
                        sx={commonFormControlLabelSx}
                      />
                    </RadioGroup>
                  </FormGroup>
                </Box>}
            </>
          }


          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer >
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <EnhancedTableRows
              classes={classes}
              rows={rows}
              page={page}
              total={total}
            />
          </Box>
        </TableContainer>

        <Box
          classes={{ root: classes.cardActionsRoot }}
          component={CardActions}
          justifyContent="flex-end"
        >
          {pagination &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={offset}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageRows}
            />
          }
        </Box>
      </Card>
    </StyledEngineProvider>
  );
};

export default CommonTable;
