import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';  
import { currentUser } from "services/user-service";
import {Edit as EditIcon, Delete as DeleteIcon} from "@mui/icons-material"

const useStyles = makeStyles(componentStyles);

const InventoryMaterialList = () => {
  const classes = useStyles();
  const [materialList, setMaterialList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const columns = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType:'string' },
    { id: 'Type', numeric: false, disablePadding: false, label: 'Type', dataType:'string' },
    { id: 'Unit', numeric: false, disablePadding: false, label: 'Unit', dataType:'string'},
    { id: 'MinQuantity', numeric: false, disablePadding: false, label: 'Min Quantity', dataType:'string' },
    { id: 'Description', numeric: false, disablePadding: false, label: 'Description', dataType:'string' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'InventoryMaterialsList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setMaterialList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    };
    
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]) 
 

  const handleEdit = (row) => {
    history.push(`update-material/${row.MaterialId}`)
  };

  
  const handleDelete = async (row) => {
    let res = await dataService('post', `InventoryMaterialDelete/${row.MaterialId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  let actionList = [
    {label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit},
    {label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true},
  ]


if(currentUser()?.Role !== 'SUPER_ADMIN'){
  actionList = actionList.filter(ac => {
    return ac.label !== 'Edit'
  })

  actionList = actionList.filter(ac => {
    return ac.label !== 'Delete'
  })
}
  
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
          <CommonTable 
            title="Materials List"
            columns={columns}
            rows={materialList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            actions={actionList}
            setOffset={setOffset}
            offset={offset}
            loading={isLoading}
            viewURL="create-material"
            view="material"
          />
        </Container>
    </>
  );
};

export default InventoryMaterialList;