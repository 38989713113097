import React from "react";
import { Checkbox, DialogContent} from '@mui/material';
function ViewShadeCard(props) {
    const { orderData, handleShowPrice, showPrice } = props;

    return (
        <DialogContent>
            <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Full</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFull}</dd>
                            <dt className="text-sm font-medium text-gray-500">Full Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFullMtr}</dd>

                            <dt className="text-sm font-medium text-gray-500">Main</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBMain}</dd>
                            <dt className="text-sm font-medium text-gray-500">Main Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBMainMtr}</dd>
                        </div>


                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.PBRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Cutting</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Small Piece Patta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingSmallPiecePatta}</dd>
                            <dt className="text-sm font-medium text-gray-500">Small Piece Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingSmallPieceSize}</dd>
                            <dt className="text-sm font-medium text-gray-500">Table</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingTable}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Filler Patta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingFillerPatta}</dd>
                            <dt className="text-sm font-medium text-gray-500">Filler Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingFillerSize}</dd>
                            <dt className="text-sm font-medium text-gray-500">Total </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingTotal}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" color="primary" />ZigZag</span>
                            <span className=""><Checkbox checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" color="primary" />Interlock</span>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.CuttingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Binding</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Back to Back</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300 sm:col-span-2">{orderData?.BindingsBackToBack}</dd>
                            <dt className="text-sm font-medium text-gray-500">Total</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{orderData?.BindingsTotal}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.BindingsDieCut === 1 ? true : false} name="TagZigZag" color="primary" />Die Cut</span>
                            <span className=""><Checkbox checked={orderData?.BindingsFoil === 1 ? true : false} name="TagInterlock" color="primary" />Foil</span>
                            <span className=""><Checkbox checked={orderData?.BindingsTagPrinted === 1 ? true : false} name="TagInterlock" color="primary" />Tag Printed</span>
                            <span className=""><Checkbox checked={orderData?.BindingsMDF === 1 ? true : false} name="TagInterlock" color="primary" />MDF</span>
                            <span className=""><Checkbox checked={orderData?.BindingsCappaBoard === 1 ? true : false} name="TagInterlock" color="primary" />Cappa Board</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.BindingsRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-7 sm:gap-2 sm:px-5 items-center">
                            <dt className="text-sm font-medium text-gray-500">Printer</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-indigo-300">{orderData?.PhotosPrinter}</dd>
                            <dt className="text-sm font-medium text-gray-500 col-start-3 col-end-5">Photo Paper GSM</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-indigo-300">{orderData?.PhotosPaperGSM}</dd>
                            <dt className="text-sm font-medium text-gray-500">Photo Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PhotosSize}</dd>

                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-5 items-center">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                Page
                                <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage" color="primary" />1</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage" color="primary" />2</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage" color="primary" />3</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                            </dd>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" color="primary" />Lamination</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosUV === 1 ? true : false} name="PhotosUV" color="primary" />UV</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" color="primary" />Matt</span>
                                <span className=""><Checkbox checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" color="primary" />Glossy</span>
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-5">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.PhotosRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Gumming</dt>
                            <dd className="text-sm font-medium text-gray-500 md:border-r border-purple-300">{orderData?.ScreenGumming}</dd>
                            <dt className="text-sm font-medium text-gray-500">Total</dt>
                            <dd className="text-sm font-medium text-gray-500 md:border-r border-purple-300">{orderData?.ScreenTotal}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.ScreenRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fittings</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Filler</dt>
                            <dd className="text-sm font-medium text-gray-500 md:border-r border-pink-300">{orderData?.FittingFiller}</dd>
                            <dt className="text-sm font-medium text-gray-500">Total</dt>
                            <dd className="text-sm font-medium text-gray-500">{orderData?.FittingTotal}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-9">{orderData?.FittingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
        </DialogContent>
    );
};
export default ViewShadeCard;