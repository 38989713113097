import { DialogContent, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { CustomButton } from 'components/Common/CustomButton';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';

function QRCodeDialog(props){
	const { onClose, openDialog, QRCodeURL, title, description } = props;
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={openDialog} disableBackdropClick onClose={handleClose} aria-labelledby="form-dialog-title">

            <div className='bg-blue-500 border-blue-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">{title}</span><br />
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            
            <DialogContent className="pb-0">
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        {description}
                    </Grid>

                    <Grid item xs={12} lg={12}>
                    <div className="flex flex-wrap items-center justify-center my-2 w-full sm:max-w-sm xl:max-w-md ml-auto mr-auto">
                            <div className="bg-white rounded mt-3 p-4 w-4/5">
                                <QRCodeSVG  id="qrcode" value={QRCodeURL} size="1000" level="Q" style={{ height: "100%", width: "100%" }} />
                                {/* <QRCode bgColor="#11B8EF"  fgColor="#11B8EF" id="qrcode" value={QRCodeURL} size="1000" style={{ height: "100%", width: "100%" }} /> */}
                            </div>
                        </div>
                        
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="pt-0">
                <CustomButton label="Ok" onClick={handleClose} />
            </DialogActions>
        </Dialog>
	);
};
export default QRCodeDialog;