import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { Edit, Delete as DeleteIcon } from "@mui/icons-material"

const useStyles = makeStyles(componentStyles);

const InventoryRequiredPerFile = () => {
  const classes = useStyles();
  const [requiredPerFileList, setRequiredPerFileList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    { id: 'MaterialName', numeric: false, disablePadding: true, label: 'Material Name', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product Name', dataType: 'string' },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Create Date', dataType: 'date' },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let res = await dataService('get', 'InventoryMaterialsRequiredPerFileList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setRequiredPerFileList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  const handleEdit = (row) => {
    history.push(`update-material-required-per-file/${row.RequiredPerFileId}`)
  };


  const handleDelete = async (row) => {
    try {
      let res = await dataService('post', `InventoryMaterialsRequiredPerFileDelete/${row.RequiredPerFileId}`, null);
      if (res && (res.status === 200 || res.status === 304)) {
        setIsReload(!isReload);
      }
    } catch (e){
      console.log(e)
    }
   
  };

  let actionList = [
    { label: 'Edit', icon: Edit, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
  ]

  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })

    actionList = actionList.filter(ac => {
      return ac.label !== 'Delete'
    })
  }

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Required Per File"
          columns={columns}
          rows={requiredPerFileList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          viewURL="create-material-required-per-file"
          view="required per file"
        />
      </Container>
    </>
  );
};

export default InventoryRequiredPerFile;