import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { Edit, Delete, Person, CalendarMonth, Visibility } from "@mui/icons-material";
import CommonTable from "components/Tables/CommonTable";
import { FormControl, MenuItem, Select } from "@mui/material";
import { renderTitleDetails } from "utils/helper";
import place_dots from 'assets/img/place_dots.png';
import moment from "moment";
import ViewPreOrderDialog from "components/dialoges/ViewPreOrderDialog";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles(componentStyles);

const PreOrders = () => {
  const classes = useStyles();
  const [preOrderList, setPreOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [orderFilter, setOrderFilter] = useState({
    Pending: 1,
    Approved: null,
    Canceled: null
  });

  const [openPreOrderView, setOpenPreOrderView] = useState({ data: null, isVisible: false })

  const [isReload, setIsReload] = useState(true);

  const viewAction = (row) => {
    setOpenPreOrderView({
      data: row,
      isVisible: true
    })
  };


  const columns = [
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'CollectionName', numeric: false, disablePadding: false, label: 'Collection', dataType: 'string' },
    { id: 'Logo', numeric: false, disablePadding: false, label: 'Logo', dataType: 'string' },
    { id: 'Size', numeric: false, disablePadding: false, label: 'Size', dataType: 'string' },
    { id: 'Buckram', numeric: false, disablePadding: false, label: 'Buckram', dataType: 'string' },
    { id: 'SKU', numeric: false, disablePadding: false, label: 'SKU', dataType: 'string' },
    { id: 'MTR', numeric: false, disablePadding: false, label: 'MTR', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    {
      id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Create Detail', dataType: 'component',
      Component: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CalendarMonth />
            <img src={place_dots} alt="" width={8} />
            <Person />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
            {renderTitleDetails(`${moment(row?.CreatedAt).format("DD-MM-YYYY")}`, 25, { fontSize: 12, fontWeight: 500, textOverflow: 'ellipsis', whiteSpace: 'nowrap' })}
            <div style={{ height: '2px' }} />
            {renderTitleDetails(row?.CreatedByName || "Customer", 25, { fontSize: 12, fontWeight: 500, textOverflow: 'ellipsis', whiteSpace: 'nowrap' })}
          </Box>
        </Box>
      ),
    },
    {
      id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'component',
      Component: ({ row }) => {
        const themeData = createTheme({
          components: {
            MuiSelect: {
              styleOverrides: {
                select: {
                  color: "gray", 
                  fontWeight: "bold", 
                  height: "35px", 
                  // border: row.Status === "Approved" ? "2px solid #4CAF50" : row.Status === "Pending"
                  //   ? "2px solid #11CDEF"  : row.Status === "Canceled" ? "2px solid #F44336"  : "2px solid gray", 
                  padding: "0 12px", 
                  backgroundColor: "transparent", 
                  display: "inline-flex", 
                  alignItems: "center", 
                  justifyContent: "center", 
                  boxSizing: "border-box",
                },
              },
            },
          },
        });
        
      
        return (
          <div>
            <ThemeProvider theme={themeData}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select
                  labelId="status-select-label"
                  value={row.Status}
                  disabled={row.Status === "Approved"}
                  onChange={async (e) => {
                    try {
                      let res = await dataService("post", `PreOrderStatusUpdate/${row?.PreOrderId}`, {
                        Status: e.target.value,
                      });
                      if (res.status === 200) {
                        let resData = res?.data;
                        if (resData) {
                          setIsReload(!isReload);
                        }
                      }
                    } catch (error) {
                      console.log("Error in updating status", error);
                    }
                  }}
                  size="small"
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Canceled">Canceled</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let statusFilter = Object.keys(orderFilter).find(key => orderFilter[key] !== null); 
      let query = `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`;
      if (statusFilter) {
        query += `&statusFilter=${statusFilter}`;
    }
    
      let res = await dataService('get', 'PreOrderList', query);

      if (res?.status === 200 || res?.status === 304) {
        setPreOrderList(res?.data);
        setTotalRecord(res?.totalRecord);
      }
      setIsLoading(false);
    }

    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload, orderFilter])

  const handleEdit = (row) => {
    history.push(`update-pre-order/${row.PreOrderId}`)
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `PreOrderDelete/${row.PreOrderId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const tableData = () => {

    return <CommonTable
      title="Pre Orders"
      columns={columns}
      rows={preOrderList}
      page={page - 1}
      total={totalRecord}
      pagedata={setPage}
      setSearchText={setSearchText}
      searchText={searchText}
      order={order}
      orderBy={orderBy}
      orderData={setOrderData}
      orderByData={setOrderByData}
      setOffset={setOffset}
      offset={offset}
      loading={isLoading}
      view="Pre Order"
      actions={actionList}
      viewURL="pre-order"
      preOrder={true}
      setOrderFilter={setOrderFilter}
      orderFilter={orderFilter}
    />
  }

  let actionList = [
    { label: 'View', icon: Visibility, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: Edit, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: Delete, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
  ]

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {tableData()}
        {openPreOrderView.isVisible &&
          <ViewPreOrderDialog
            onClose={() => {
              setOpenPreOrderView({ data: null, isVisible: false })
            }}
            open={openPreOrderView}
            isReload={isReload}
            setIsReload={setIsReload}
          />}
      </Container>
    </>
  );
};

export default PreOrders;