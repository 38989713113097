import React from "react";
import { Checkbox, DialogContent} from '@mui/material';

function ViewECatalogue(props) {
    const { orderData } = props;

    return (
        <DialogContent>
            <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photoshoot</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECataloguePhotoShoot ? true : false} name="ECataloguePhotoShoot" color="primary" />Photoshoot</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fabric Layout</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueFabricLayout ? true : false} name="ECatalogueFabricLayout" color="primary" />Fabric Layout</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Color Matching</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueColorMatching ? true : false} name="ECatalogueColorMatching" color="primary" />Color Matching</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Approval</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueApproval ? true : false} name="ECatalogueApproval" color="primary" />Approval</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Mail</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueMail ? true : false} name="ECatalogueMail" color="primary" />Mail</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Delivery</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueDelivery ? true : false} name="ECatalogueDelivery" color="primary" />Delivery</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
        </DialogContent>
    );
};
export default ViewECatalogue;