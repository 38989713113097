import React from 'react';
import { useLocation, Switch, Redirect} from 'react-router-dom';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AccountCircle, Person, FlagOutlined, Tv, AccountBalance as AccountBalanceIcon, Tune as TuneIcon, CardTravel as CardTravelIcon, FileCopyOutlined, Work, AssignmentTurnedIn as AssignmentTurnedInIcon, PanTool as PanToolIcon, Search, PriceChangeOutlined, ReportOutlined, MenuBook, CheckCircle, FactCheck, Notifications as NotificationsIcon, Inventory, Store, Shop2, InsertDriveFile, ReceiptLong } from "@mui/icons-material";

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import NavbarDropdown from 'components/Dropdowns/NavbarDropdown.js';

import componentStyles from 'assets/theme/layouts/admin.js';
import PrivateRoute from 'components/PrivateRoute';
import { currentUser } from '../services/user-service';
import Dashboard from 'views/admin/Dashboard';
import { useSelector } from 'react-redux';

import Users from "views/admin/user/Users.js";
import UserProfile from "views/admin/user/User";
import WorkCenters from "views/admin/workcenter/WorkCenters";
import Machines from "views/admin/machine/Machines";
import WorkCenter from "views/admin/workcenter/WorkCenter";
import Machine from "views/admin/machine/Machine";
import Products from "views/admin/product/Products";
import Product from "views/admin/product/Product";
import Customers from "views/admin/customer/Customers";
import CustomerProfile from "views/admin/customer/Customer";
import ProductFiles from "views/admin/productFile/ProductFiles";
import ProductFile from "views/admin/productFile/ProductFile";
import GoodsEntries from "views/admin/goodsEntry/GoodsEntries";
import GoodsEntry from "views/admin/goodsEntry/GoodsEntry";
// import OrderFlowProductList from "views/admin/orderFlow/ProductList";
import RunningOrders from "views/admin/orders/flow/RunningOrders";
import AddOrder from "views/admin/orders/Order";
import OrderFlow from "views/admin/orders/flow/OrderFlow";
import HoldedOrders from "views/admin/orders/flow/HoldedOrders";
import CompletedOrders from "views/admin/orders/flow/CompletedOrder";
import DeliveredOrders from "views/admin/orders/flow/DeliveredOrders";
import { DeliveredOrderIcon } from "components/Icons/CustomIcons";
import { CompletedOrderIcon } from "components/Icons/CustomIcons";
import { AddOrderIcon } from "components/Icons/CustomIcons";
import TaskList from "views/admin/Task/TaskList";
import Task from "views/admin/Task/Task";
import MyTaskList from "views/admin/Task/MyTaskList";
import ScrappedOrders from "views/admin/orders/flow/ScrappedOrders";
import FileDataList from "views/admin/File/Files";
import SalesReport from "views/admin/reports/salesReport";
import DailySalesReport from "views/admin/reports/dailySalesReport";
import DailyDetailSalesReport from "views/admin/reports/dailyDetailSalesReport";
import CustomerDataReport from "views/admin/reports/customerDataReport";
import CustomerOrderReport from "views/admin/reports/customerOrderReport";
import CustomerGoodsEntries from "views/admin/customer/goodsEntry/GoodsEntries";
import CustomerGoodsEntry from "views/admin/customer/goodsEntry/CustomerGoodsEntry";
import CustomerDailyOrderReport from "views/admin/reports/customerDailyOrderReport";
import CustomerDailyDetailOrderReport from "views/admin/reports/customerDailyDetailOrderReport";
import CustomerCompletedOrders from "views/admin/customer/orders/flow/CustomerCompletedOrders";
import CustomerDataReportMonthly from "views/admin/reports/customerDataReportMonthly";
import ProductionReports from "views/admin/reports/productionReports";
import ProductionReportsDaily from "views/admin/reports/productionReportsDaily";
import HoldReasonList from "views/admin/holdReason/HoldReasons";
import HoldReason from "views/admin/holdReason/HoldReason";
import ProductionReportsDailyDetail from "views/admin/reports/productionReportsDailyDetail";
import { GoodsEntryIcon, OrderFlowIcon, HoldedOrderIcon, SofaIcon, ScrappedOrderIcon, PreGoodsEntryIcon } from 'components/Icons/CustomIcons';
import { IconButton, TextField } from '@mui/material';
import QuotationsList from 'views/admin/quotation/Quotations';
import GoodsEntryReport from 'views/admin/reports/goodsEntryReport';
import DailyGoodsEntryReport from 'views/admin/reports/dailyGoodsEntryReport';
import DailyDetailGoodsEntryReport from 'views/admin/reports/dailyDetailGoodsEntryReport';
import DetailGoodsEntryOrderReport from 'views/admin/reports/detailGoodsEntryOrderReport';
import CatalogueData from 'views/admin/catalogue';
import CreateCatalogue from 'views/admin/catalogue/components/CreateCatalogue';
import FlowCheckList from 'views/admin/flowCheckList/FlowCheckList';
import FlowCheckItem from 'views/admin/flowCheckList/FlowCheckItem';
import PreOrder from 'views/admin/preOrder/PreOrder';
import PreOrders from 'views/admin/preOrder/PreOrdersList';
import GECheckList from 'views/admin/GECheckList/GECheckList';
import GECheckItem from 'views/admin/GECheckList/GECheckItem';
import Notifications from 'views/admin/notifications/Notifications';
import InventoryMaterialList from 'views/admin/InventoryMaterial/MaterialList';
import CreateMaterial from 'views/admin/InventoryMaterial/CreateMaterial';
import InventoryStocksList from 'views/admin/InventoryStock/StocksList';
import CreateStock from 'views/admin/InventoryStock/CreateStock';
import InventoryRequiredPerFile from 'views/admin/InventoryRequiredPerFile/RequiredPerFileList';
import CreateRequiredPerFile from 'views/admin/InventoryRequiredPerFile/CreateRequiredPerFile';
import InventoryPurchaseOrders from 'views/admin/InventoryPurchaseOrders/PurchaseOrders';
import CreateInventoryPurchaseOrder from 'views/admin/InventoryPurchaseOrders/CreatePurchaseOrder';
import InventoryStockTransactionsList from 'views/admin/InventoryStockTransactions/StockTransactionsList';
import InventoryWorkOrdersMaterialList from 'views/admin/InventoryWorkOrdersMaterial/InventoryWorkOrdersMaterialList';

const useStyles = makeStyles(componentStyles);

const Admin = () => {
  const classes = useStyles();
  const location = useLocation();

  const selectedOrderFlow = useSelector(state => state.selectedOrderFlow); // show/hide cart sidebar


  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const routes = [
    {
      path: "/index",
      name: "Dashboard",
      icon: Tv,
      iconColor: "Primary",
      component: Dashboard,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR'],
      data: selectedOrderFlow

    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/my-task",
      name: "My Task",
      icon: AssignmentTurnedInIcon,
      iconColor: "Primary",
      component: MyTaskList,
      layout: "/admin",
      showInSidebar: true,
      roles: ["SUPER_ADMIN", "ADMIN", "MASTER_OPERATOR", "OPERATOR"],
    },
    {
      path: "/task-list",
      name: "Task List",
      icon: AssignmentTurnedInIcon,
      iconColor: "Primary",
      component: TaskList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/pre-orders",
      name: "Pre Orders",
      icon: PreGoodsEntryIcon,
      iconColor: "Primary",
      component: PreOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/pre-order",
      component: PreOrder,
      layout: "/admin",
      showInSidebar: false,
      name: "Pre Order",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-pre-order/:id",
      component: PreOrder,
      layout: "/admin",
      showInSidebar: false,
      name: "Pre Order",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/goods-entries",
      name: "Goods Entry",
      icon: GoodsEntryIcon,
      iconColor: "Primary",
      component: GoodsEntries,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/goods-entry",
      component: GoodsEntry,
      layout: "/admin",
      showInSidebar: false,
      name: "Goods Entry",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-goods-entry/:id",
      component: GoodsEntry,
      layout: "/admin",
      showInSidebar: false,
      name: "Goods Entry",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/sync-goods-entry/:customerId/:customerGoodEntry",
      component: GoodsEntry,
      layout: "/admin",
      showInSidebar: false,
      name: "Goods Entry",
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      title: "Orders",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/order/add",
      name: "Add Order",
      icon: AddOrderIcon,
      iconColor: "Primary",
      component: AddOrder,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN']
    },
    {
      path: "/order-copy/:CopyOrderId",
      component: AddOrder,
      layout: "/admin",
      showInSidebar: false,
      name: "Order",
      roles: ['SUPER_ADMIN', 'ADMIN']
    },
    {
      path: "/order/:id",
      component: AddOrder,
      layout: "/admin",
      showInSidebar: false,
      name: "Order",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/order-by-goodsentry/:GoodsEntryId/:GoodsEntryFileId",
      component: AddOrder,
      layout: "/admin",
      showInSidebar: false,
      name: "Order",
      roles: ['SUPER_ADMIN', 'ADMIN']
    },
    {
      path: "/orders/running",
      name: "Running Orders",
      icon: SofaIcon,
      iconColor: "Primary",
      component: RunningOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/orderflow/holded-orders",
      name: "Holded Orders",
      icon: HoldedOrderIcon,
      iconColor: "Primary",
      component: HoldedOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/orderflow/scrapped-orders",
      name: "Scrapped Orders",
      icon: ScrappedOrderIcon,
      iconColor: "Primary",
      component: ScrappedOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/orderflow/delivered-orders",
      name: "Delivered Orders",
      icon: DeliveredOrderIcon,
      iconColor: "Primary",
      component: DeliveredOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/orderflow/completed-orders",
      name: "Completed Orders",
      icon: CompletedOrderIcon,
      iconColor: "Primary",
      component: CompletedOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    // {
    //   path: "/orderflow/product-list",
    //   name: "Pending Orders",
    //   icon: PendingOrderIcon,
    //   iconColor: "Primary",
    //   component: OrderFlowProductList,
    //   layout: "/admin",
    //   showInSidebar: true,
    //   roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR',  'MASTER_OPERATOR']
    // },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      title: "Order Flow",
      //name: "Order Flow",
      showInSidebar: true,
      //path: "/orderflow",
      //layout: "/admin",
      //component: OrderProductData,
      iconColor: "Primary",
      icon: OrderFlowIcon,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    {
      path: "/orderflow/:flow/:WorkCenterId",
      name: "Order Flow",
      component: OrderFlow,
      layout: "/admin",
      showInSidebar: true,
      accordian: true,
      roles: ['SUPER_ADMIN', 'ADMIN', 'OPERATOR', 'MASTER_OPERATOR']
    },
    // {
    //   divider: true,
    //   showInSidebar: true,
    //   roles: ['SUPER_ADMIN']
    // },
    // {
    //   path: "/task-list",
    //   name: "Task List",
    //   icon: AssignmentTurnedInIcon,
    //   iconColor: "Primary",
    //   component: TaskList,
    //   layout: "/admin",
    //   showInSidebar: true,
    //   roles: ['SUPER_ADMIN']
    // },
    {
      path: "/task",
      component: Task,
      layout: "/admin",
      showInSidebar: false,
      name: "Task",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-task/:id",
      component: Task,
      layout: "/admin",
      showInSidebar: false,
      name: "Task",
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      title: "Master",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/users",
      name: "Users",
      icon: AccountCircle,
      iconColor: "Primary",
      component: Users,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: NotificationsIcon,
      iconColor: "Primary",
      component: Notifications,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/user",
      component: UserProfile,
      layout: "/admin",
      showInSidebar: false,
      name: "User",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-user/:id",
      component: UserProfile,
      layout: "/admin",
      showInSidebar: false,
      name: "User",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/workcenters",
      name: "Workcenters",
      icon: AccountBalanceIcon,
      iconColor: "Info",
      component: WorkCenters,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/workcenter",
      component: WorkCenter,
      layout: "/admin",
      showInSidebar: false,
      name: "WorkCenter",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-workcenter/:id",
      component: WorkCenter,
      layout: "/admin",
      showInSidebar: false,
      name: "WorkCenter",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/machines",
      name: "Machines",
      icon: TuneIcon,
      iconColor: "ErrorLight",
      component: Machines,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/machine",
      component: Machine,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-machine/:id",
      component: Machine,
      layout: "/admin",
      showInSidebar: false,
      name: "Machine",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/products",
      name: "Products",
      icon: CardTravelIcon,
      iconColor: "InfoLight",
      component: Products,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/product",
      component: Product,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-product/:id",
      component: Product,
      layout: "/admin",
      showInSidebar: false,
      name: "Product",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/customers",
      name: "Customers",
      icon: Person,
      iconColor: "PrimaryLight",
      component: Customers,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/customer",
      component: CustomerProfile,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-customer/:id",
      component: CustomerProfile,
      layout: "/admin",
      showInSidebar: false,
      name: "Product",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/product-files",
      name: "File Size",
      icon: Work,
      iconColor: "Warning",
      component: ProductFiles,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/product-file",
      component: ProductFile,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-product-file/:id",
      component: ProductFile,
      layout: "/admin",
      showInSidebar: false,
      name: "File Size",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/files",
      name: "File",
      icon: FileCopyOutlined,
      iconColor: "Warning",
      component: FileDataList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/hold-reasons",
      name: "Hold Reason",
      icon: PanToolIcon,
      iconColor: "Warning",
      component: HoldReasonList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/hold-reason",
      component: HoldReason,
      layout: "/admin",
      showInSidebar: false,
      name: "Hold Reason",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-hold-reason/:id",
      component: HoldReason,
      layout: "/admin",
      showInSidebar: false,
      name: "Hold Reason",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/catalogues",
      name: "Catalogue",
      icon: MenuBook,
      iconColor: "PrimaryLight",
      component: CatalogueData,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/create-catalogue",
      component: CreateCatalogue,
      layout: "/admin",
      showInSidebar: false,
      name: "Catalogue",
      roles: ['SUPER_ADMIN'],
      hideSidebar: true
    },
    {
      path: "/update-catalogue/:id",
      component: CreateCatalogue,
      layout: "/admin",
      showInSidebar: false,
      name: "Catalogue",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/flow-check-list",
      name: "Flow Check List",
      icon: CheckCircle,
      iconColor: "Warning",
      component: FlowCheckList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/flow-check-item",
      component: FlowCheckItem,
      layout: "/admin",
      showInSidebar: false,
      name: "Check Item",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-flow-check-item/:id",
      component: FlowCheckItem,
      layout: "/admin",
      showInSidebar: false,
      name: "Check Item",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/goods-entry-check-list",
      name: "GE Check List",
      icon: FactCheck,
      iconColor: "Warning",
      component: GECheckList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/goods-entry-check-item",
      name: "GE Check List",
      component: GECheckItem,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-goods-entry-check-item/:id",
      component: GECheckItem,
      layout: "/admin",
      showInSidebar: false,
      name: "GE Check Item",
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      title: "Stock Management",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-materials",
      name: "Inventory Materials",
      icon: Inventory,
      iconColor: "Primary",
      component: InventoryMaterialList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/create-material",
      name: "Inventory Materials",
      component: CreateMaterial,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-material/:id",
      name: "Inventory Materials",
      component: CreateMaterial,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-stocks",
      name: "Inventory Stocks",
      icon: Store,
      iconColor: "Primary",
      component: InventoryStocksList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/create-stock",
      name: "Inventory Stocks",
      component: CreateStock,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-stock/:id",
      name: "Inventory Stocks",
      component: CreateStock,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-material-required-per-file",
      name: "Material Required File",
      icon: InsertDriveFile,
      iconColor: "Primary",
      component: InventoryRequiredPerFile,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/create-material-required-per-file",
      name: "Material Required File",
      component: CreateRequiredPerFile,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-material-required-per-file/:id",
      name: "Material Required File",
      component: CreateRequiredPerFile,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-purchase-orders",
      name: "Purchase Orders",
      icon: Shop2,
      iconColor: "Primary",
      component: InventoryPurchaseOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/create-inventory-purchase-order",
      name: "Purchase Orders",
      component: CreateInventoryPurchaseOrder,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/update-inventory-purchase-order/:id",
      name: "Purchase Orders",
      component: CreateInventoryPurchaseOrder,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-stock-transactions",
      name: "Stock Transactions",
      icon: ReceiptLong,
      iconColor: "Primary",
      component: InventoryStockTransactionsList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/inventory-work-order-materials",
      name: "Work Orders Material",
      icon: Work,
      iconColor: "Primary",
      component: InventoryWorkOrdersMaterialList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      title: "Reports",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    // {
    //   path: "/report/orders",
    //   name: "Order Report",
    //   icon: CompletedOrderIcon,
    //   iconColor: "Primary",
    //   component: OrderReport,
    //   layout: "/admin",
    //   showInSidebar: true,
    //   roles: ["SUPER_ADMIN"]
    // },
    {
      path: "/report/sales-report",
      name: "Sales Report",
      icon: CompletedOrderIcon,
      iconColor: "Primary",
      component: SalesReport,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/daily-sales-report/:year/:month",
      parenPath: "/report/sales-report",
      component: DailySalesReport,
      layout: "/admin",
      showInSidebar: false,
      name: "Daily Sales Report",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/daily-detail-sales-report/:year/:month/:day",
      name: "Daily Detail Sales Report",
      component: DailyDetailSalesReport,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/customers",
      name: "Customers",
      icon: Person,
      iconColor: "PrimaryLight",
      component: CustomerDataReport,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/customers-report",
      name: "Customers Report",
      icon: Person,
      iconColor: "PrimaryLight",
      component: CustomerDataReportMonthly,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/productions-report",
      name: "Production Reports",
      icon: FlagOutlined,
      iconColor: "PrimaryLight",
      component: ProductionReports,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/goods-entry-report",
      name: "Goods Entry Reports",
      icon: ReportOutlined,
      iconColor: "PrimaryLight",
      component: GoodsEntryReport,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/daily-goods-entry-report/:year/:month",
      parenPath: "/report/goods-entry-report",
      component: DailyGoodsEntryReport,
      layout: "/admin",
      showInSidebar: false,
      name: "Daily Sales Report",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/daily-detail-goods-entry-report/:year/:month/:day",
      name: "Daily Detail Goods Entry Report",
      component: DailyDetailGoodsEntryReport,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/detail-goods-entry-order-report/:goodsEntryId",
      name: "Detail Goods Entry Order Report",
      component: DetailGoodsEntryOrderReport,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/productions-report-daily/:year/:month/:workCenertId",
      name: "Production Daily Detail Reports",
      icon: Person,
      iconColor: "PrimaryLight",
      component: ProductionReportsDaily,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/productions-report-daily-detail/:year/:month/:workCenertId/:day/:workCenerDateId",
      name: "Production Daily Reports",
      icon: Person,
      iconColor: "PrimaryLight",
      component: ProductionReportsDailyDetail,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    // {
    //   path: "/report/customers-data",
    //   name: "Customers Data",
    //   icon: Person,
    //   iconColor: "PrimaryLight",
    //   component: CustomerDataReport,
    //   layout: "/admin",
    //   showInSidebar: true,
    //   roles: ['SUPER_ADMIN']
    // },
    {
      path: "/report/customer-order/:customerId",
      name: "Customers Order Data",
      icon: Person,
      iconColor: "PrimaryLight",
      component: CustomerOrderReport,
      layout: "/admin",
      showInSidebar: false,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/daily-customer-order/:customerId/:year/:month",
      component: CustomerDailyOrderReport,
      layout: "/admin",
      showInSidebar: false,
      name: "Daily Sales Report",
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/report/customer-daily-details/:customerId/:year/:month/:day",
      component: CustomerDailyDetailOrderReport,
      layout: "/admin",
      showInSidebar: false,
      name: "Daily Sales Report",
      roles: ['SUPER_ADMIN']
    },
    {
      divider: true,
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    },
    {
      path: "/customer-goods-entries",
      name: "Goods Entry",
      icon: GoodsEntryIcon,
      iconColor: "Primary",
      component: CustomerGoodsEntries,
      layout: "/admin",
      showInSidebar: true,
      roles: ["CUSTOMER"]
    },
    {
      path: "/customer-goods-entry",
      name: "Goods Entry",
      icon: GoodsEntryIcon,
      iconColor: "Primary",
      component: CustomerGoodsEntry,
      layout: "/admin",
      showInSidebar: false,
      roles: ["CUSTOMER"]
    },
    {
      path: "/customer-update-goods-entry/:id",
      component: CustomerGoodsEntry,
      layout: "/admin",
      showInSidebar: false,
      name: "Goods Entry",
      roles: ['CUSTOMER']
    },
    {
      path: "/orderflow/customer-completed-orders",
      name: "Orders",
      icon: CompletedOrderIcon,
      iconColor: "Primary",
      component: CustomerCompletedOrders,
      layout: "/admin",
      showInSidebar: true,
      roles: ['CUSTOMER']
    },
    {
      path: "/quotations",
      name: "Quotations",
      icon: PriceChangeOutlined,
      iconColor: "Warning",
      component: QuotationsList,
      layout: "/admin",
      showInSidebar: true,
      roles: ['SUPER_ADMIN']
    }
  ];

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={
              prop?.roles?.includes(currentUser()?.Role)
                ? prop.component
                : Dashboard
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getCustomerRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={
              prop.component

            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Orders";
  };


  return (
    <>
      <>
          <Sidebar
            routes={routes}
            logo={{
              innerLink: currentUser()?.CustomerId ? '/admin/customer-goods-entries' : '/admin/index',
              imgSrc: require('../assets/img/brand/rajsampler-logo.png'),
              //src={require("../ ../assets/img/brand/rajsampler-logo.png").default},
              //imgSrc: "https://rajsampler.com/clientmedia/cfsimages/rajsampler_logo_w200.png",
              imgAlt: '...',
            }}
            dropdown={<NavbarDropdown />}
            input={
              <>
                <TextField
                  className={classes.textinput}
                  label="Search"
                  variant="outlined"
                  fullWidth

                  InputProps={{
                    endAdornment: (
                      <IconButton >
                        <Search className='!w-5 !h-5' />
                      </IconButton>
                    ),
                  }}
                />
              </>
            }
          />
        <Box
          position="relative"
          className={`${classes.mainContent} admin-header-main`}
          style={{ minHeight: 'calc(100vh - 65px)' }}
        >
          <AdminNavbar brandText={getBrandText(location.pathname)} />
          <Switch>
            {currentUser()?.CustomerId ? getCustomerRoutes(routes)
              : getRoutes(routes)}
            

            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          ></Container>
        </Box>
        <AdminFooter />
      </>
    </>
  );
};

export default Admin;
