import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { Edit as EditIcon, Delete as DeleteIcon, CheckCircle } from "@mui/icons-material"

const useStyles = makeStyles(componentStyles);

const InventoryPurchaseOrders = () => {
  const classes = useStyles();
  const [requiredPerFileList, setPurchaseOrdersList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      id: 'MaterialName', numeric: false, disablePadding: false, label: 'Material', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div style={{display : "flex", flexDirection : "row", alignItems : "center", gap : 2}}>
            {row?.CurrentInUsed ? <CheckCircle fontSize="large" color={ "success" }  /> : null} {row.MaterialName} 
          </div>
        )
      }},
    {
      id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'Component',
      Component: ({ row }) => {
        return (
          <div>
            {row.Quantity} {row.MaterialUnit}
          </div>
        )
      }
    },
    { id: 'PricePerUnit', numeric: false, disablePadding: false, label: 'Price Unit', dataType: 'string' },
    { id: 'TotalAmount', numeric: false, disablePadding: false, label: 'Total Amount', dataType: 'string' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
    { id: 'OrderDate', numeric: false, disablePadding: false, label: 'Order Date', dataType: 'date' },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let res = await dataService('get', 'InventoryPurchaseOrdersList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&MaterialId=${0}`);
      setPurchaseOrdersList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  const handleEdit = (row) => {
    history.push(`update-inventory-purchase-order/${row.PurchaseOrderId}`)
  };


  const handleDelete = async (row) => {
    try {
      let res = await dataService('post', `InventoryPurchaseOrderDelete/${row.PurchaseOrderId}`, null);
      if (res && (res.status === 200 || res.status === 304)) {
        setIsReload(!isReload);
      }
    } catch (e) {
      console.log(e)
    }

  };

  let actionList = [
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit, hide: (row) => row?.Status === "Fulfilled" },
    { label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
  ]

  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })

    actionList = actionList.filter(ac => {
      return ac.label !== 'Delete'
    })
  }

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Purchase Orders"
          columns={columns}
          rows={requiredPerFileList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          viewURL="create-inventory-purchase-order"
          view="Purchase Order"
        />
      </Container>
    </>
  );
};

export default InventoryPurchaseOrders;